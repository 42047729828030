var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"w-max relative min-w-[300px]"},[_c('table',{staticClass:"custom-table w-full"},[_c('thead',[_c('th',[_vm._v("No")]),(_vm.showDay)?_c('th',[_vm._v("D")]):_vm._e(),_c('th',[_vm._v("Num")]),_c('th',[_vm._v(_vm._s(_vm.t('Big')))]),_c('th',[_vm._v(_vm._s(_vm.t('Small')))]),_c('th',[_vm._v("T")])]),_c('tbody',_vm._l((_vm.entries),function(entry,$index){return _c('tr',{key:$index,class:entry.mode,on:{"keypress":(event) => {
          _vm.handleKeyPresses(event, entry);
        }}},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s($index + 1 + _vm.startingIndex)+".")]),(_vm.showDay)?_c('td',{staticClass:"font-bold text-center text-black",class:[
          `day-${entry.day}`,
          entry.day !== _vm.selectedDay ? 'blink' : undefined,
        ]},[_vm._v(" "+_vm._s(entry.day)+" ")]):_vm._e(),_c('td',[_c('InputText',{ref:"number",refInFor:true,staticClass:"dark-input uppercase",attrs:{"type":"text","minlength":0,"maxlength":4},on:{"keydown":(event) => {
              _vm.handleXInput(event, entry);
              _vm.handleArrowKey(event, 'number', $index);
              _vm.handlePlusMinusKey(event, 'number', $index);
              _vm.handleEnter(event, 'number', $index);
            },"input":(val) => {
              entry.number = val.replaceAll(/[^0-9xX]/g, '');
            }},model:{value:(entry.number),callback:function ($$v) {_vm.$set(entry, "number", $$v)},expression:"entry.number"}})],1),_c('td',[_c('InputText',{ref:"big",refInFor:true,staticClass:"dark-input no-spinner",attrs:{"type":"number"},on:{"keydown":(event) => {
              _vm.handleArrowKey(event, 'big', $index);
              _vm.handlePlusMinusKey(event, 'big', $index);
              _vm.handleEnter(event, 'big', $index);
            },"blur":(event) => {
              _vm.handleUnfocus(event, 'big', $index);
            },"input":(val) => {
              entry.big = String(val).replaceAll(/[^0-9\.]/g, '') || '';
            }},model:{value:(entry.big),callback:function ($$v) {_vm.$set(entry, "big", _vm._n($$v))},expression:"entry.big"}})],1),_c('td',[_c('InputText',{ref:"small",refInFor:true,staticClass:"dark-input no-spinner",attrs:{"type":"number"},on:{"keydown":(event) => {
              _vm.handleArrowKey(event, 'small', $index);
              _vm.handlePlusMinusKey(event, 'small', $index);
              _vm.handleEnter(event, 'small', $index);
            },"blur":(event) => {
              _vm.handleUnfocus(event, 'small', $index);
            },"input":(val) => {
              entry.small = String(val).replaceAll(/[^0-9]/g, '') || '';
            }},model:{value:(entry.small),callback:function ($$v) {_vm.$set(entry, "small", _vm._n($$v))},expression:"entry.small"}})],1),_c('td',{staticClass:"dark-input"},[(entry.mode)?_c('span',[_vm._v(_vm._s(entry.mode))]):_vm._e()])])}),0)]),_c('table',{staticClass:"w-full custom-footer-table"},[_c('tbody',[_c('tr',[_c('td',{staticClass:"label"},[_vm._v("B")]),_c('td',[_vm._v(_vm._s(_vm.calculations.bigCount.toFixed(2)))]),_c('td',{staticClass:"label"},[_vm._v("S")]),_c('td',[_vm._v(_vm._s(_vm.calculations.smallCount.toFixed(0)))])]),_c('tr',[_c('td',{staticClass:"label"},[_vm._v("i B")]),_c('td',[_vm._v(_vm._s(_vm.calculations.iBigCount.toFixed(2)))]),_c('td',{staticClass:"label"},[_vm._v("i S")]),_c('td',[_vm._v(_vm._s(_vm.calculations.iSmallCount.toFixed(0)))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }