
import { Vue, Component } from "vue-property-decorator";

import { queries, mutations } from "@/apollo";
import { User, Release, AppMode, StoredTtPage } from "@/CodegenTypes";

import { parseTtt } from "@/helpers/ttt/parseTtt";
import { summariseStoredPage } from "@/helpers/ttt/summariseStoredPage";

import { formatDollar } from "@/helpers/formatDollar";
import { errorMutationNotification } from "@/utils";
import AccountSelectModal from "@/components/modules/account/AccountSelectModal.vue";

import { TT_DAY_MAPPING } from "@/constants";
import { customMoment } from "@/main";
import { TTDay } from "@/types";
import { getTtValidDayOptions } from "@/helpers/getTtValidDayOptions";
import { useTranslation } from "@/store/translation";
import { useDialogStore } from "@/store/dialogs";

@Component({
  apollo: {
    releases: {
      query: queries.releases,
      variables() {
        return {
          appMode: AppMode.Tt,
        };
      },
    },
    user: {
      query: queries.userWithCreditSummary,
      skip() {
        return !this.selectedAccountId;
      },
      variables() {
        return {
          userId: this.selectedAccountId,
        };
      },
    },
    storedTtPages: {
      query: queries.userStoredTtPages,
      variables() {
        return {
          storedPageType: "upload",
          includeDownlines: true,
          userId: this.selectedAccountId,
        };
      },
      update(data) {
        return data.user.storedTtPages;
      },
    },
  },
  components: {
    AccountSelectModal,
  },
})
export default class TTUpload extends Vue {
  showAccountSelectionModal = false;

  releases = null as null | Release[];
  user = null as null | User;

  storedTtPages = null as null | StoredTtPage[];

  isSubmitting = false;

  t = useTranslation().$t;

  selectedDayIndex = 0;

  // uploaded = [] as {
  //   file: File;
  //   content: string;
  //   releases: Release[];
  //   uploadedAt: Date;
  //   page: string;
  //   uploadedBy: User["userId"];
  //   behalfOf: User["userId"];

  //   // warnings: { [s: string]: boolean };
  // }[];
  resetFileUploadKey = Symbol();

  formatDollar = formatDollar;

  get openReleases() {
    return this.releases?.filter((release) => release.windowIsOpen);
  }

  get selectedAccountId() {
    return this.$route.query.userId;
  }

  get selectedAccount() {
    return this.user;
  }

  get validDayOptions() {
    if (!this.releases) {
      return [];
    }
    const validOptions = getTtValidDayOptions(this.releases);
    return validOptions;
  }

  get dayOptions() {
    const OPTIONS = [
      { label: "1=<Mon>", value: 1, defaultPriority: false },
      { label: "4=<Thu>", value: 4, defaultPriority: false },
      { label: "5=<Mon,Fri>", value: 5, defaultPriority: false },
    ];

    return OPTIONS.filter((o) => this.validDayOptions.includes(o.value));
  }

  get selectedDay() {
    if (this.releases) {
      return this.dayOptions[this.selectedDayIndex].value as TTDay;
    } else {
      return 5;
    }
  }

  set selectedDay(val) {
    const index = this.dayOptions.findIndex(
      (opt) => opt.value === val
    ) as TTDay;

    this.selectedDayIndex = index;
  }

  get selectedReleases() {
    const days = TT_DAY_MAPPING[this.selectedDay];
    return this.releases?.filter((r) => days.includes(r.releaseDay));
  }

  get summarisedUploads() {
    return this.storedTtPages?.map((storedPage) => ({
      ...storedPage,
      summary: summariseStoredPage(storedPage),
      // summary: summariseAra(storedPage.content, this.selectedDay),
      warnings: {
        noContainUserId:
          !!this.user &&
          !storedPage.uploadFileName?.toLowerCase().includes(this.user.userId),
        fileNameExists:
          this.storedTtPages &&
          this.storedTtPages.filter(
            (p) => p.uploadFileName === storedPage.uploadFileName
          ).length > 1,
      },
    }));
  }

  formatReleasesToText(releases: Release[]) {
    const releaseDates = releases.map((r) => r.releaseDate);
    const releaseDateTexts = releaseDates.map((rd) => this.formatDate(rd));

    return releaseDateTexts.join(" & ");
  }

  handleFileUpload({ files }: { files: File[] }) {
    const file = files[0];
    const reader = new FileReader();
    reader.addEventListener("load", async (event) => {
      const line = event.target?.result;

      if (!this.selectedReleases || !this.selectedAccountId) {
        throw new Error("Missing variables");
      }

      try {
        const parsedTtts = parseTtt(line as string, this.selectedDay);
        if (parsedTtts.length === 0) {
          throw new Error("No valid entries found");
        }
        const firstParsedTtt = parsedTtts[0];
        await this.$apollo.mutate({
          mutation: mutations.saveTtEntry,
          variables: {
            page: {
              // id: this.selectedPageId || undefined,
              page: firstParsedTtt.page,
              entries: parsedTtts.map((parsedTtt) => ({
                day: this.selectedDay,
                number1: parsedTtt.numbers[0],
                number2: parsedTtt.numbers[1],
                number3: parsedTtt.numbers[2],
                number4: parsedTtt.numbers[3],
                number5: parsedTtt.numbers[4],
                number6: parsedTtt.numbers[5],
                number7: parsedTtt.numbers[6],
                number8: parsedTtt.numbers[7],
                number9: parsedTtt.numbers[8],
                number10: parsedTtt.numbers[9],
                unit: parsedTtt.unit,
                mode: parsedTtt.mode,
              })),
              uploadMeta: {
                fileName: file.name,
                fileSize: file.size,
              },
              userId: this.selectedAccountId,
              storedPageType: "upload",
            },
          },
        });
        this.resetFileUploadKey = Symbol();
        this.reloadAll();

        useDialogStore().addDialog({
          text: "File uploaded successfully! You can see your upload below. Click on green load button to confirm.",
        });
      } catch (err) {
        errorMutationNotification(err);
      }
    });
    if (file) {
      reader.readAsText(file);
    }
  }

  formatDatetime(date: Date) {
    return customMoment(date).format("DD MMM YYYY hh:mm:ss A");
  }
  formatDate(date: Date) {
    return customMoment(date).format("ddd, DD MMM YYYY");
  }

  async submitFile(index: number) {
    const targetUpload = this.storedTtPages?.[index];
    if (!targetUpload) {
      return;
    }
    try {
      // do submit
      this.isSubmitting = true;

      await this.$apollo.mutate({
        mutation: mutations.insertTtEntry,
        variables: {
          page: {
            page: targetUpload.name,
            userId: this.selectedAccountId,
            entries: targetUpload.entries.map((e) => {
              const numbers = e.numbersText.split("-");
              return {
                number1: numbers[0],
                number2: numbers[1],
                number3: numbers[2],
                number4: numbers[3],
                number5: numbers[4],
                number6: numbers[5],
                number7: numbers[6],
                number8: numbers[7],
                number9: numbers[8],
                number10: numbers[9],
                day: e.day,
                unit: e.unit,
                mode: e.mode,
              };
            }),
            entrySource: "upload",
          },
        },
      });
      this.reloadAll();
      useDialogStore().addDialog({
        text: "Upload successfully loaded into bet list.",
      });
      await this.handleDelete(targetUpload.id, false);

    } catch (err) {
      errorMutationNotification(err);
    } finally {
      this.isSubmitting = false;
    }
  }

  async handleDelete(pageId: string, doPrompt = true) {
  if (!this.selectedAccountId) {
    this.$toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'No account selected',
      life: 3000
    });
    return;
  }

  const performDelete = async () => {
    try {
      this.isSubmitting = true;
      await this.$apollo.mutate({
        mutation: mutations.deleteSavedTtPage,
        variables: {
          id: pageId,
        },
      });
      this.reloadAll();
      if (doPrompt) {
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Upload deleted.',
          life: 3000
        });
      }
    } catch (err) {
      errorMutationNotification(err);
    } finally {
      this.isSubmitting = false;
    }
  };

  if (doPrompt) {
    this.$confirm.require({
      header: 'Confirm?',
      message: 'Are you sure to delete the upload?',
      icon: 'pi pi-exclamation-triangle',
      accept: performDelete,
      reject: () => {
        // Optional: Handle rejection
      }
    });
  } else {
    await performDelete();
  }
}


  reloadAll() {
    Object.values(this.$apollo.queries).forEach((q) => q.refetch());
  }

  async mounted() {
    const priorityIndex = this.dayOptions.findIndex(
      (opt) => opt.defaultPriority
    );
    Vue.set(this, "selectedDayIndex", priorityIndex === -1 ? 0 : priorityIndex);

    const releaseResult = await this.$apollo.query<{ releases: Release[] }>({
      query: queries.releases,
      variables: {
        appMode: AppMode.Tt,
      },
    });
    this.releases = releaseResult.data.releases;
  }
}
