import { render, staticRenderFns } from "./PaymentCollection.vue?vue&type=template&id=9a1dbfb8&scoped=true&"
import script from "./PaymentCollection.vue?vue&type=script&lang=ts&setup=true&"
export * from "./PaymentCollection.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.7.1_lodash@4.17.21_react@18.2.0_vue-template-compiler@2.7.10_webpack@5.74.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a1dbfb8",
  null
  
)

export default component.exports