import { PaymentType } from "@/CodegenTypes";

export const AUTH_TYPES = {
  REQUIRE_LOGGED_IN: "REQUIRE_LOGGED_IN",
  REQUIRE_NOT_LOGGED_IN: "REQUIRE_NOT_LOGGED_IN",
};

export const DAY_MAPPING = {
  0: [3, 6, 7],
  2: [6, 7],
  3: [3],
  6: [6],
  7: [7],
};

export const TT_DAY_MAPPING = {
  1: [1],
  4: [4],
  5: [1, 4],
};

export const PAYMENT_TYPE_DISPLAY = {
  [PaymentType.DdBet]: { display: "4D Betting", isSystemType: true },
  [PaymentType.DdOutsetClaim]: {
    display: "4D Share Claim",
    isSystemType: true,
  },
  [PaymentType.DdPositionShare]: {
    display: "4D Position + Expense",
    isSystemType: true,
  },
  [PaymentType.DdStrikePayout]: {
    display: "4D Strike Payout",
    isSystemType: true,
  },
  [PaymentType.TtBet]: { display: "TOTO Betting", isSystemType: true },
  [PaymentType.TtBetD1]: { display: "TOTO Betting A1", isSystemType: true },
  [PaymentType.TtBetD2]: { display: "TOTO Betting D2", isSystemType: true },
  [PaymentType.TtBetD3]: { display: "TOTO Betting D3", isSystemType: true },
  [PaymentType.TtBetD4]: { display: "TOTO Betting D4", isSystemType: true },
  [PaymentType.TtOutsetClaim]: {
    display: "TOTO Share Claim",
    isSystemType: true,
  },
  [PaymentType.TtOutsetClaimD1]: {
    display: "TOTO Share Claim A1",
    isSystemType: true,
  },
  [PaymentType.TtOutsetClaimD2]: {
    display: "TOTO Share Claim D2",
    isSystemType: true,
  },
  [PaymentType.TtOutsetClaimD3]: {
    display: "TOTO Share Claim D3",
    isSystemType: true,
  },
  [PaymentType.TtOutsetClaimD4]: {
    display: "TOTO Share Claim D4",
    isSystemType: true,
  },
  [PaymentType.TtPositionShare]: {
    display: "TOTO Position + Expense",
    isSystemType: true,
  },
  [PaymentType.TtPositionShareD1]: {
    display: "TOTO Position + Expense A1",
    isSystemType: true,
  },
  [PaymentType.TtPositionShareD2]: {
    display: "TOTO Position + Expense D2",
    isSystemType: true,
  },
  [PaymentType.TtPositionShareD3]: {
    display: "TOTO Position + Expense D3",
    isSystemType: true,
  },
  [PaymentType.TtPositionShareD4]: {
    display: "TOTO Position + Expense D4",
    isSystemType: true,
  },
  [PaymentType.TtStrikePayout]: {
    display: "TOTO Strike Payout",
    isSystemType: true,
  },
  [PaymentType.TtStrikePayoutD1]: {
    display: "TOTO Strike Payout A1",
    isSystemType: true,
  },
  [PaymentType.TtStrikePayoutD2]: {
    display: "TOTO Strike Payout D2",
    isSystemType: true,
  },
  [PaymentType.TtStrikePayoutD3]: {
    display: "TOTO Strike Payout D3",
    isSystemType: true,
  },
  [PaymentType.TtStrikePayoutD4]: {
    display: "TOTO Strike Payout D4",
    isSystemType: true,
  },
  [PaymentType.PaymentReceived]: {
    display: "Payment Received +",
    isSystemType: false,
  },
  [PaymentType.StrikePayout]: {
    display: "Strike Payout -",
    isSystemType: false,
  },
  [PaymentType.OtherPositive]: {
    display: "Other +",
    isSystemType: false,
  },
  [PaymentType.OtherNegative]: {
    display: "Other -",
    isSystemType: false,
  },
};

export const TT_MODE_NAMES = {
  R: "R",
  R2: "R2",
  R3: "R3",
  R4: "R4",
  R5: "R5",
  R7: "R7",
  twoWild: "2*",
  threeWild: "3*",
  fourWild: "4*",
  wild2: "*2",
  wild3: "*3",
  wild4: "*4",
  TO: "TO",
  TE: "TE",
  TT: "TT",
} as { [s: string]: string };

export const ZERO_TO_HUNDRED_PERCENT = [
  { label: "0%", value: 0 },
  { label: "1%", value: 1 },
  { label: "2%", value: 2 },
  { label: "3%", value: 3 },
  { label: "4%", value: 4 },
  { label: "5%", value: 5 },
  { label: "6%", value: 6 },
  { label: "7%", value: 7 },
  { label: "8%", value: 8 },
  { label: "9%", value: 9 },
  { label: "10%", value: 10 },
  { label: "11%", value: 11 },
  { label: "12%", value: 12 },
  { label: "13%", value: 13 },
  { label: "14%", value: 14 },
  { label: "15%", value: 15 },
  { label: "16%", value: 16 },
  { label: "17%", value: 17 },
  { label: "18%", value: 18 },
  { label: "19%", value: 19 },
  { label: "20%", value: 20 },
  { label: "21%", value: 21 },
  { label: "22%", value: 22 },
  { label: "23%", value: 23 },
  { label: "24%", value: 24 },
  { label: "25%", value: 25 },
  { label: "26%", value: 26 },
  { label: "27%", value: 27 },
  { label: "28%", value: 28 },
  { label: "29%", value: 29 },
  { label: "30%", value: 30 },
  { label: "31%", value: 31 },
  { label: "32%", value: 32 },
  { label: "33%", value: 33 },
  { label: "34%", value: 34 },
  { label: "35%", value: 35 },
  { label: "36%", value: 36 },
  { label: "37%", value: 37 },
  { label: "38%", value: 38 },
  { label: "39%", value: 39 },
  { label: "40%", value: 40 },
  { label: "41%", value: 41 },
  { label: "42%", value: 42 },
  { label: "43%", value: 43 },
  { label: "44%", value: 44 },
  { label: "45%", value: 45 },
  { label: "46%", value: 46 },
  { label: "47%", value: 47 },
  { label: "48%", value: 48 },
  { label: "49%", value: 49 },
  { label: "50%", value: 50 },
  { label: "51%", value: 51 },
  { label: "52%", value: 52 },
  { label: "53%", value: 53 },
  { label: "54%", value: 54 },
  { label: "55%", value: 55 },
  { label: "56%", value: 56 },
  { label: "57%", value: 57 },
  { label: "58%", value: 58 },
  { label: "59%", value: 59 },
  { label: "60%", value: 60 },
  { label: "61%", value: 61 },
  { label: "62%", value: 62 },
  { label: "63%", value: 63 },
  { label: "64%", value: 64 },
  { label: "65%", value: 65 },
  { label: "66%", value: 66 },
  { label: "67%", value: 67 },
  { label: "68%", value: 68 },
  { label: "69%", value: 69 },
  { label: "70%", value: 70 },
  { label: "71%", value: 71 },
  { label: "72%", value: 72 },
  { label: "73%", value: 73 },
  { label: "74%", value: 74 },
  { label: "75%", value: 75 },
  { label: "76%", value: 76 },
  { label: "77%", value: 77 },
  { label: "78%", value: 78 },
  { label: "79%", value: 79 },
  { label: "80%", value: 80 },
  { label: "81%", value: 81 },
  { label: "82%", value: 82 },
  { label: "83%", value: 83 },
  { label: "84%", value: 84 },
  { label: "85%", value: 85 },
  { label: "86%", value: 86 },
  { label: "87%", value: 87 },
  { label: "88%", value: 88 },
  { label: "89%", value: 89 },
  { label: "90%", value: 90 },
  { label: "91%", value: 91 },
  { label: "92%", value: 92 },
  { label: "93%", value: 93 },
  { label: "94%", value: 94 },
  { label: "95%", value: 95 },
  { label: "96%", value: 96 },
  { label: "97%", value: 97 },
  { label: "98%", value: 98 },
  { label: "99%", value: 99 },
  { label: "100%", value: 100 },
];

export const ZERO_TO_THIRTY_PERCENT = [
  { label: "0%", value: 0 },
  { label: "1%", value: 1 },
  { label: "2%", value: 2 },
  { label: "3%", value: 3 },
  { label: "4%", value: 4 },
  { label: "5%", value: 5 },
  { label: "6%", value: 6 },
  { label: "7%", value: 7 },
  { label: "8%", value: 8 },
  { label: "9%", value: 9 },
  { label: "10%", value: 10 },
  { label: "11%", value: 11 },
  { label: "12%", value: 12 },
  { label: "13%", value: 13 },
  { label: "14%", value: 14 },
  { label: "15%", value: 15 },
  { label: "16%", value: 16 },
  { label: "17%", value: 17 },
  { label: "18%", value: 18 },
  { label: "19%", value: 19 },
  { label: "20%", value: 20 },
  { label: "21%", value: 21 },
  { label: "22%", value: 22 },
  { label: "23%", value: 23 },
  { label: "24%", value: 24 },
  { label: "25%", value: 25 },
  { label: "26%", value: 26 },
  { label: "27%", value: 27 },
  { label: "28%", value: 28 },
  { label: "29%", value: 29 },
  { label: "30%", value: 30 },
];
