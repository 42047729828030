
import { Vue, Component } from "vue-property-decorator";
import DeletedPageDetailsModal from "@/components/modules/bet-details/DeletedPageDetailsModal.vue";
import UserBreadcrumb from "@/components/common/UserBreadcrumb.vue";
import { queries } from "@/apollo";
import { User, DdPage, DeletedDdPage } from "@/CodegenTypes";

import { sumBy, startCase, first } from "lodash";
import { formatDollar } from "@/helpers/formatDollar";
import { formatCount } from "@/helpers/formatCount";
import AccountSelectModal from "@/components/modules/account/AccountSelectModal.vue";
import AccountSelectSearch from "@/components/modules/account/AccountSelectSearch.vue";
import { customMoment } from "@/main";
import { useTranslation } from "@/store/translation";

@Component({
  apollo: {
    user: {
      query: queries.userDeletedPages,
      skip() {
        return !this.selectedStartReleaseDate;
      },
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          releaseDate: customMoment(this.selectedStartReleaseDate)
            .startOf("day")
            .toDate(),
          behalfOf: this.$route.query.userId,
        };
      },
    },
  },
  components: {
    UserBreadcrumb,
    DeletedPageDetailsModal,
    AccountSelectModal,
    AccountSelectSearch,
  },
})
export default class DeletedBetDetails extends Vue {
  user = null as null | User;

  showAccountSelectionModal = false;

  startCase = startCase;

  formatDollar = formatDollar;
  formatCount = formatCount;

  t = useTranslation().$t;

  selectedPage = null as null | DeletedDdPage;
  showPageDetailModal = false;

  get selectedStartReleaseDate() {
    const query = this.$route.query.startReleaseDate;
    return Array.isArray(query) ? first(query) : query;
  }

  get pageDetailTotal() {
    const data = this.pageDetailsData;
    return {
      big: sumBy(data, (d) => d.big).toFixed(2),
      small: sumBy(data, (d) => d.small).toFixed(2),
      amount: formatDollar(sumBy(data, (d) => d.amount)),
    };
  }

  get pageDetailsData() {
    const currUser = this.user;
    if (!currUser) {
      return [];
    }
    const downlinesData = currUser.deletedPages
      .map((page) => {
        const big = page.calculation.bigCount;
        const small = page.calculation.smallCount;
        const amount = page.calculation.rawCost;
        return {
          ...page,
          upline: page.userId,
          uplineName: page.username,
          account: page.userId,
          username: page.username,
          big,
          small,
          amount,
          accountCount: currUser.deletedPages.filter(
            (u) => u.userId === page.userId
          ).length,
          createdAt: page.createdAt,
        };
      })
      .filter((u) => u.amount > 0)
      .sort(
        (u1, u2) =>
          customMoment(u1.createdAt).unix() - customMoment(u2.createdAt).unix()
      )
      .sort((u1, u2) => u1.upline.localeCompare(u2.upline));

    const rowData = downlinesData.map((row) => {
      return {
        ...row,
        uplineCount: downlinesData.filter((d) => d.upline === row.upline)
          .length,
        accountCount: downlinesData.filter((d) => d.account === row.account)
          .length,
      };
    });
    return rowData;
  }

  formatDate(dateStr: string) {
    return customMoment(dateStr).format("ddd, DD/MM/YYYY hh:mm:ss A");
  }

  reloadAll() {
    Object.values(this.$apollo.queries).forEach((q) => q.refresh());
  }

  viewPageDetails(id: DdPage["id"]) {
    const page = this.pageDetailsData.find((p) => p.id === id);
    if (!page) {
      return;
    }
    this.selectedPage = page;
    this.showPageDetailModal = true;
  }
}
