var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Loader',{staticClass:"w-full max-w-[600px]",model:{value:(_setup.loading),callback:function ($$v) {_setup.loading=$$v},expression:"loading"}},[_c('section',{staticClass:"overflow-x-auto max-w-full"},[_c('h2',[_vm._v(_vm._s(_setup.$t("Downline summary")))]),_c('table',{staticClass:"table-auto theme-table hoverable striped w-full"},[_c('thead',[_c('th',[_vm._v(_vm._s(_setup.$t("Account")))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_setup.$t("Big")))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_setup.$t("Small")))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_setup.$t("Amount")))])]),_c('tbody',{},_vm._l((_setup.downlineSummaryData),function(row){return _c('tr',{key:row.userId},[_c('td',[(_setup.user && row.userId !== _setup.user.userId)?_c('span',[_c('router-link',{staticClass:"uppercase text-primary-600",attrs:{"to":{
                  name: _setup.route.name,
                  query: {
                    ..._setup.route.query,
                    userId: row.userId,
                  },
                }}},[_vm._v(_vm._s(row.userId))]),_c('span',{staticClass:"text-sm text-gray-500"},[_vm._v(" "+_vm._s(row.username)+" ")])],1):_c('span',[_c('span',{staticClass:"uppercase font-medium"},[_vm._v(" "+_vm._s(row.userId)+" ")])])]),_c('td',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(_setup.formatCount(row.big)))])]),_c('td',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(_setup.formatCount(row.small)))])]),_c('td',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(_setup.formatDollar(row.amount)))])])])}),0),_c('tfoot',[_c('tr',[_c('td',{staticClass:"text-right; pr-[40px]",attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_setup.$t("Total"))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_setup.downlineSummaryTotal.big)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_setup.downlineSummaryTotal.small)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_setup.downlineSummaryTotal.amount)+" ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }