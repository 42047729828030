
import { Vue, Component, Watch } from "vue-property-decorator";
import MassEntryTable2 from "@/components/modules/entry/MassEntryTable2.vue";
import EditRowsModal, {
  EditForm,
} from "@/components/modules/entry/EditRowsModal.vue";
import AccountSelectModal from "@/components/modules/account/AccountSelectModal.vue";
import CopyBetModal from "@/components/modules/entry/CopyBetModal.vue";
import { queries, mutations } from "@/apollo";
import { Release, User, DdPage } from "@/CodegenTypes";
import { Day, EntryInput } from "@/types";
import { parseEntryInputs } from "@/helpers/dd/parseEntryInputs";
import { calculateEntryCost } from "@/helpers/dd/calculateEntryCost";
import { calculateEntries } from "@/helpers/dd/calculateEntries";
import { getValidDayOptions } from "@/helpers/getValidDayOptions";
import { clamp } from "lodash";
import { validateNumber } from "@/helpers/dd/validateNumber";
import { formatDollar } from "@/helpers/formatDollar";
import { errorMutationNotification } from "@/utils";
import { customMoment } from "@/main";
import PageDetailsModal from "../../modules/bet-details/PageDetailsModal.vue";
import ViewBetModal from "../../modules/entry/ViewBetModal.vue";
import { useRootStore } from "@/store/pinia";
import { useTranslation } from "@/store/translation";

@Component({
  components: {
    CopyBetModal,
    MassEntryTable2,
    EditRowsModal,
    AccountSelectModal,
    PageDetailsModal,
    ViewBetModal,
  },
  apollo: {
    releases: {
      query: queries.releases,
      variables() {
        return {
          appMode: useRootStore().appMode,
        };
      },
    },
    user: {
      query: queries.userWithCreditSummary,
      skip() {
        return !this.selectedAccountId;
      },
      variables() {
        return {
          userId: this.selectedAccountId,
        };
      },
    },
  },
})
export default class MassEntry2 extends Vue {
  releases = null as null | Release[];
  selectedDayIndex = 0;
  page = "";
  user = null as null | User;
  showEditModal = false;

  isSubmitting = false;
  showAccountSelectionModal = false;
  showPageDetailsModal = false;
  showCopyBetModal = false;
  showViewBetModal = false;

  t = useTranslation().$t;

  successPageId = null;

  editForm = {
    rowsFrom: undefined as undefined | number,
    rowsTo: undefined as undefined | number,
    big: undefined as undefined | EntryInput["big"],
    small: undefined as undefined | EntryInput["small"],
    mode: undefined as undefined | EntryInput["mode"],
  };

  formatDollar = formatDollar;

  get selectedAccountId() {
    return this.$route.query.userId;
  }

  get userId() {
    return useRootStore().account?.userId;
  }

  get MAX_ROWS() {
    if (this.isSimple) {
      return 20;
    } else {
      return 60;
    }
  }

  get tables() {
    if (this.isSimple) {
      return [
        {
          startingIndex: 0,
        },
      ];
    } else {
      return [
        { startingIndex: 0 },
        { startingIndex: 20 },
        { startingIndex: 40 },
      ];
    }
  }

  entries = [] as EntryInput[];

  get isSimple() {
    return this.$route.name === "simple entry";
  }

  get selectedAccount() {
    return this.user;
  }

  get creditForDisplay() {
    return {
      creditGiven: this.selectedAccount?.creditGiven || 0,
      creditUsed: this.selectedAccount?.creditSummary.creditUsed || 0,
      creditBalance: this.selectedAccount?.creditSummary.creditBalance || 0,
    };
  }

  get selectedDay() {
    if (this.releases) {
      return this.dayOptions[this.selectedDayIndex].value as Day;
    } else {
      return 0;
    }
  }

  set selectedDay(val) {
    const index = this.dayOptions.findIndex((opt) => opt.value === val) as Day;

    this.selectedDayIndex = index;
  }

  get openReleases() {
    return this.releases?.filter((release) => release.windowIsOpen);
  }

  async mounted() {
    await this.initPage(true);
  }

  async initPage(isRedirect = false) {
    // while (!this.releases) {
    //   await new Promise(resolve => {
    //     setTimeout(() => {
    //       resolve();
    //     }, 10);
    //   });
    // }

    const result = await this.$apollo.query<{ releases: Release[] }>({
      query: queries.releases,
      variables: {
        appMode: useRootStore().appMode,
      },
    });
    this.releases = result.data.releases;
    const priorityIndex = this.dayOptions.findIndex(
      (opt) => opt.defaultPriority
    );
    Vue.set(this, "selectedDayIndex", priorityIndex === -1 ? 0 : priorityIndex);
    if (!isRedirect) {
      this.entries = [];
    }
    for (let i = 0; i < this.MAX_ROWS; i++) {
      this.entries.push({
        day: this.selectedDay,
        number: "",
        big: undefined,
        small: undefined,
        mode: undefined,
      });
    }
  }

  get validEntries() {
    return parseEntryInputs(this.entries);
  }

  get calculations() {
    return calculateEntries(this.validEntries);
  }

  get validDayOptions() {
    if (!this.releases) {
      return [];
    }
    const validOptions = getValidDayOptions(this.releases);
    return validOptions;
  }

  get dayOptions() {
    const OPTIONS = [
      { label: "0:We|Sa|Su", value: 0, defaultPriority: false },
      { label: "2:Sa|Su", value: 2, defaultPriority: false },
      { label: "3:We", value: 3, defaultPriority: true },
      { label: "6:Sa", value: 6, defaultPriority: false },
      { label: "7:Su", value: 7, defaultPriority: false },
    ];

    return OPTIONS.filter((o) => this.validDayOptions.includes(o.value));
  }

  get totalCost() {
    const costs = this.validEntries.map((entry) => {
      return calculateEntryCost(entry);
    });
    const bigCosts = costs.reduce((sum, cost) => {
      sum += cost.big;
      return sum;
    }, 0);
    const smallCosts = costs.reduce((sum, cost) => {
      sum += cost.small;
      return sum;
    }, 0);

    return {
      big: bigCosts,
      small: smallCosts,
    };
  }

  handleCopyPage(page: DdPage) {
    this.$router.push({
      name: "mass entry 2",
      query: { ...this.$route.query },
    });

    this.doClearEntries();
    const newEntries = [...this.entries];
    for (const [index, entry] of page.entries.entries()) {
      newEntries[index] = {
        number: entry.number,
        day: this.selectedDay,
        big: entry.big,
        small: entry.small,
        mode: entry.mode ? entry.mode : undefined,
      };
    }
    this.entries = newEntries;
    this.page = page.name;
    return;
  }

  toggleEditModal() {
    this.showEditModal = !this.showEditModal;
  }

  getDayFromDate(dateStr: string) {
    const day = customMoment(dateStr).isoWeekday();
    return day;
  }

  changeSelectedDay(newVal: Day) {
    for (const entry of this.entries) {
      entry.day = newVal;
    }
  }
  changeAllMode(mode: undefined | "R" | "i" | "k") {
    for (const entry of this.entries) {
      entry.mode = mode;
    }
  }

  focus(field: "number" | "big" | "small", inputTargetIndex: number) {
    let targetIndex = inputTargetIndex;
    if (targetIndex < 0) {
      targetIndex = 59;
    } else if (targetIndex > 59) {
      targetIndex = 0;
    }
    const tablesEles = this.$refs["mass-entry-table"] as any;
    for (const [i, table] of this.tables.entries()) {
      const startingIndex = table.startingIndex;
      if (targetIndex >= startingIndex && targetIndex < startingIndex + 20) {
        tablesEles[i].focus(field, targetIndex);
      }
    }
    return;
  }

  performEditRows(editForm: EditForm) {
    if (!editForm.rowsFrom || !editForm.rowsTo) {
      return;
    }

    // make sure in range
    const rowsFrom = clamp(editForm.rowsFrom, 0, this.MAX_ROWS);
    const rowsTo = clamp(editForm.rowsTo, 0, this.MAX_ROWS);

    // make sure rowsFrom is smaller than rowsTo
    const range = [rowsFrom, rowsTo].sort((a, b) => a - b);
    const newEntries = [...this.entries];
    for (let i = range[0] - 1; i <= range[1] - 1; i++) {
      newEntries[i].big = editForm.big;
      newEntries[i].small = editForm.small;
      newEntries[i].mode = editForm.mode;
    }
    this.entries = newEntries;
    this.editForm = {
      rowsFrom: undefined,
      rowsTo: undefined,
      big: undefined,
      small: undefined,
      mode: undefined,
    };
    this.showEditModal = false;
  }

  async clearEntries(event: Event) {
    this.$confirm.require({
      target: event.currentTarget,
      group: 'popups',
      header: "Clear the form?",
      message: "All existing inputs will be deleted.",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        // callback to execute when user confirms the action
        this.doClearEntries();
      },
      reject: () => {
        // callback to execute when user rejects the action
      },
    });
  }
  doClearEntries() {
    const newEntries = [];

    for (let i = 0; i < this.MAX_ROWS; i++) {
      newEntries.push({
        day: this.selectedDay,
        number: "",
        big: undefined,
        small: undefined,
        mode: undefined,
      });
    }
    this.entries = newEntries;
  }

  validateAllAndShowError() {
    const errors = [];
    for (const [index, entry] of this.entries.entries()) {
      const validNumber = validateNumber(entry);
      const noSmall =
        (entry.small as any) === "" ||
        entry.small === undefined ||
        Number(entry.small) === 0;
      const noBig =
        (entry.big as any) === "" ||
        entry.big === undefined ||
        Number(entry.big) === 0;
      const hasMode = entry.mode;
      // if (entry.number === "" && noSmall && noBig && !hasMode) {
      if (entry.number === "" && noSmall && noBig) {
        continue;
      }
      if (!validNumber) {
        errors.push(`Row ${index + 1} has invalid number`);
      }
      if (validNumber && noSmall && noBig) {
        errors.push(`Row ${index + 1} has no big and small`);
      }
    }
    return errors;
  }

  async handleSubmit() {
  const errors = this.validateAllAndShowError();
  if (errors.length) {
    this.$toast.add({
      severity: 'error',
      summary: 'Error',
      detail: errors[0],
      life: 3000
    });
    return;
  }

  if (!this.selectedAccountId) {
    this.$toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'No account selected',
      life: 3000
    });
    return;
  }

  const entries = this.validEntries;

  if (!entries.length) {
    this.$toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Please enter number details for entries',
      life: 3000
    });
    return;
  }

  this.$confirm.require({
    header: 'Confirm?',
    message: this.t("Are you sure to submit the entries for SG4D betting?"),
    icon: 'pi pi-exclamation-triangle',
    accept: async () => {
      try {
        this.isSubmitting = true;
        const result = await this.$apollo.mutate({
          mutation: mutations.insertDdEntry,
          variables: {
            page: {
              page: this.page,
              entries,
              userId: this.selectedAccountId,
              entrySource: "massEntry",
            },
          },
        });
        this.successPageId = result.data.insertDdEntry;
        this.showPageDetailsModal = true;
        this.doClearEntries();
        this.page = "";
      } catch (err) {
        errorMutationNotification(err);
      } finally {
        this.isSubmitting = false;
      }
    },
    reject: () => {
      // Optional: Handle rejection
    }
  });
}

@Watch("isSimple")
async reinitPage() {
  return this.initPage(true);
}

formatDate(dateStr: string) {
  return customMoment(dateStr).format("ddd, DD MMM YYYY");
}

}
