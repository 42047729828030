
import { Vue, Component, Watch } from "vue-property-decorator";
import { queries } from "@/apollo";
import { User, DdPage, UserStakes } from "@/CodegenTypes";

import xlsx from "xlsx";

import { sumBy, startCase, first, sortBy } from "lodash";
import { formatDollar } from "@/helpers/formatDollar";
import { formatCount } from "@/helpers/formatCount";
import TTNumberStakeModal from "@/components/modules/bet-details/TTNumberStakeModal.vue";
import { customMoment } from "@/main";
import Clusterize from "clusterize.js";
import { useTranslation } from "@/store/translation";
import InputText from "primevue/inputtext/InputText";

type SortMode =
  | "number-asc"
  | "number-desc"
  | "inBig-asc"
  | "inBig-desc"
  | "inSmall-asc"
  | "inSmall-desc"
  | "inAmount-asc"
  | "inAmount-desc"
  | "intakeBig-asc"
  | "intakeBig-desc"
  | "intakeSmall-asc"
  | "intakeSmall-desc"
  | "intakeAmount-asc"
  | "intakeAmount-desc"
  | "outBig-asc"
  | "outBig-desc"
  | "outSmall-asc"
  | "outSmall-desc"
  | "outAmount-asc"
  | "outAmount-desc"
  | "maxPayout-asc"
  | "maxPayout-desc";

@Component({
  apollo: {
    user: {
      query: queries.user,
      variables() {
        return { userId: this.$route.query.userId };
      },
    },
    ttUserStakes: {
      query: queries.ttUserStakes,
      skip() {
        return !this.selectedReleaseDate;
      },
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          releaseDate: customMoment(this.selectedReleaseDate)
            .startOf("day")
            .toDate(),
          appMode: "DD",
          behalfOf: this.$route.query.userId,
        };
      },
    },
  },
  components: {
    TTNumberStakeModal,
  },
})
export default class UserStake extends Vue {
  user = null as null | User;

  numberFilter = "";

  selectedNumber = null as null | string;

  ttUserStakes = null as null | UserStakes;

  showAccountSelectionModal = false;

  startCase = startCase;

  formatDollar = formatDollar;
  formatCount = formatCount;

  t = useTranslation().$t;
  selectedPage = null as null | DdPage;
  showNumberStakeModal = false;

  sortMode = "number-asc" as SortMode;

  clusterize = null as Clusterize | null;

  get renderedRows() {
    const sortedRows = sortBy(this.stakeRows, (r) => r.number.length);
    const rows = sortedRows.map((row, $index) => {
      return `
      <tr class="text-center">
        <td>${$index + 1}</td>
        <td>
              <a
                id="${row.number}"
                class="font-bold text-primary-600 cursor-pointer number-row"
                >${row.number}</a
              >
            </td>
            <td colspan="3">${formatDollar(row.amountIn)}</td>
            <td colspan="3">${formatDollar(row.amountIntake)}</td>
            <td colspan="3">${formatDollar(row.amountOut)}</td>
            <td>${formatDollar(row.maxPayout)}</td>
      </tr>
      `;
    });
    return rows;
  }

  @Watch("stakeRows")
  doClusterize() {
    if (this.clusterize) {
      this.clusterize?.update(this.renderedRows);
    } else {
      this.clusterize = new Clusterize({
        rows: this.renderedRows,
        scrollId: "scrollArea",
        contentId: "contentArea",
        show_no_data_row: false,
        rows_in_block: 20,
        callbacks: {
          clusterChanged: () => {
            const numberRows = document.getElementsByClassName("number-row");
            for (const row of numberRows) {
              row.addEventListener("click", () => {
                this.handleSelectNumber(row.id);
              });
            }
          },
        },
      });
    }
  }
  mounted() {
    this.doClusterize();
  }

  startIndex = 0;
  PER_PAGE = 100;

  get stakeRowsPaginated() {
    const endIndex = this.startIndex + this.PER_PAGE;
    return this.stakeRows.slice(this.startIndex, endIndex);
  }

  get stakeRows() {
    if (!this.ttUserStakes) {
      return [];
    }
    const downlineEatStake = this.ttUserStakes.downlineEatStake;
    // const companyFinalStake = this.ttUserStakes.companyFinalStake;
    const rows = this.ttUserStakes.masterStake
      .filter((row) => row.number.includes(this.numberFilter))
      .map((row, $index) => {
        const intakeRow = downlineEatStake[$index];
        // const outRow = companyFinalStake[$index];
        const amountIn = row.unit;
        const amountIntake = intakeRow.unit;

        const amountOut = amountIn - amountIntake;
        // const maxPayout = Math.max(bigIn * 4000, smallIn * 3000);
        const maxPayout = 0;
        return {
          number: row.number,
          amountIn,
          amountIntake,
          amountOut,
          maxPayout,
        };
      });
    return sortBy(rows, (row) => {
      switch (this.sortMode) {
        case "number-asc":
          return row.number;
        case "number-desc":
          return -row.number;

        case "inAmount-asc":
          return row.amountIn;
        case "inAmount-desc":
          return -row.amountIn;

        case "intakeAmount-asc":
          return row.amountIntake;
        case "intakeAmount-desc":
          return -row.amountIntake;

        case "outAmount-asc":
          return row.amountOut;
        case "outAmount-desc":
          return -row.amountOut;
        case "maxPayout-asc":
          return row.maxPayout;
        case "maxPayout-desc":
          return -row.maxPayout;
      }
    });
  }

  get stakeSummary() {
    return {
      amountIn: sumBy(this.stakeRows, (r) => r.amountIn),
      amountIntake: sumBy(this.stakeRows, (r) => r.amountIntake),
      amountOut: sumBy(this.stakeRows, (r) => r.amountOut),
    };
  }

  get selectedReleaseDate() {
    const query = this.$route.query.startReleaseDate;
    return Array.isArray(query) ? first(query) : query;
  }

  formatDate(dateStr: string) {
    return customMoment(dateStr).format("DD MMM YYYY");
  }

  handleSelectNumber(number: string) {
    this.selectedNumber = number;
    this.showNumberStakeModal = true;
  }
  exportExcel() {
    if (!this.user || !this.stakeSummary) {
      return;
    }
    // const table = this.$refs.table;
    // const wb = xlsx.utils.table_to_book(table as HTMLElement, { raw: true });

    const wb = xlsx.utils.book_new();

    const ws_data = [] as string[][];
    const metaRow1 = [`Generated at ${customMoment()}`];
    ws_data.push(metaRow1);
    const metaRow2 = [
      `Account ${this.user.userId.toUpperCase()} ${this.formatDate(
        this.selectedReleaseDate || ""
      )} Stake`,
    ];
    ws_data.push(metaRow2);
    const headerRow1 = ["", "In", "Intake", "Out", "First Prize"];
    ws_data.push(headerRow1);

    const headerRow2 = ["Number", "Amount", "Amount", "Amount", "Max Payout"];
    ws_data.push(headerRow2);

    for (const row of this.stakeRows) {
      const rowData = [
        row.number,
        formatDollar(row.amountIn),
        formatDollar(row.amountIntake),
        formatDollar(row.amountOut),
        formatDollar(row.maxPayout),
      ];
      ws_data.push(rowData);
    }
    const footerData = [
      `Total (${this.stakeRows.length})`,
      formatDollar(this.stakeSummary.amountIn),
      formatDollar(this.stakeSummary.amountIntake),
      formatDollar(this.stakeSummary.amountOut),
      "",
    ];
    ws_data.push(footerData);

    const ws = xlsx.utils.aoa_to_sheet(ws_data);
    // ws["!merges"] = [
    // { s: { c: 0, r: 0 }, e: { c: 10, r: 0 } },
    // { s: { c: 0, r: 1 }, e: { c: 10, r: 1 } },
    // { s: { c: 1, r: 2 }, e: { c: 3, r: 2 } },
    // { s: { c: 4, r: 2 }, e: { c: 6, r: 2 } },
    // { s: { c: 7, r: 2 }, e: { c: 9, r: 2 } }
    // ];
    xlsx.utils.book_append_sheet(wb, ws, "Sheet 1");

    xlsx.writeFile(wb, `${this.user?.userId.toUpperCase()}-stake.xlsx`, {
      bookType: "xlsx",
    });
    return;
  }
}
