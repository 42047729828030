var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"w-max relative min-w-[300px]"},[_c('table',{staticClass:"custom-table"},[_c('thead',[_c('th',[_vm._v("No")]),(_vm.showDay)?_c('th',[_vm._v("D")]):_vm._e(),_c('th',[_vm._v("#1")]),_c('th',[_vm._v("#2")]),_c('th',[_vm._v("#3")]),_c('th',[_vm._v("#4")]),_c('th',[_vm._v("#5")]),_c('th',[_vm._v("#6")]),_c('th',[_vm._v("#7")]),_c('th',[_vm._v("#8")]),_c('th',[_vm._v("#9")]),_c('th',[_vm._v("#10")]),_c('th',[_vm._v("Unit")]),_c('th',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(`Type`),expression:"`Type`",modifiers:{"top":true}}]},[_vm._v("Type")]),_c('th',[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(`Is Valid`),expression:"`Is Valid`",modifiers:{"top":true}}],staticClass:"pi pi-check"})])]),_c('tbody',_vm._l((_vm.entries),function(entry,$index){return _c('tr',{key:$index,class:[entry.mode, _vm.rowCalculations[$index] ? '' : 'error'],on:{"keypress":(event) => {
            _vm.handleKeyPresses(event, entry);
          }}},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s($index + 1 + _vm.startingIndex)+".")]),(_vm.showDay)?_c('td',{staticClass:"font-bold text-center",class:[
          `day-${entry.day}`,
          entry.day !== _vm.selectedDay ? 'blink' : undefined,
        ]},[_vm._v(" "+_vm._s(entry.day)+" ")]):_vm._e(),_vm._l((_vm.numbers),function(number){return _c('td',{key:number},[_c('InputText',{ref:`number` + number,refInFor:true,staticClass:"dark-input uppercase w-[25px]",class:{
            error:
              entry[_vm.getNumberString(number)] &&
              !_vm.validateTTNumber(entry[_vm.getNumberString(number)]),
          },attrs:{"minlength":0,"maxlength":2},on:{"keydown":(event) => {
              _vm.handleArrowKey(event, 'number', $index, number);
              _vm.handlePlusMinusKey(event, 'number', $index, number);
              _vm.handleEnter(event, 'number', $index, number);
            },"input":(val) => {
              entry[_vm.getNumberString(number)] = val.replaceAll(
                /[^0-9]/g,
                ''
              );
              _vm.handleNumberInput(val, 'number', $index, number);
            }},model:{value:(entry[_vm.getNumberString(number)]),callback:function ($$v) {_vm.$set(entry, _vm.getNumberString(number), $$v)},expression:"entry[getNumberString(number)]"}})],1)}),_c('td',[_c('InputText',{ref:"unit",refInFor:true,staticClass:"dark-input no-spinner w-[40px]",attrs:{"type":"number"},on:{"keydown":(event) => {
                _vm.handleArrowKey(event, 'unit', $index, -1);
                _vm.handlePlusMinusKey(event, 'unit', $index, -1);
                _vm.handleEnter(event, 'unit', $index, -1);
              },"blur":(event) => {
                _vm.handleUnfocus(event, 'unit', $index);
              }},model:{value:(entry.unit),callback:function ($$v) {_vm.$set(entry, "unit", _vm._n($$v))},expression:"entry.unit"}})],1),_c('td',{staticClass:"w-[50px]"},[_c('div',[_c('Select',{ref:"mode",refInFor:true,staticClass:"dark-input mode",attrs:{"options":_vm.modeOptions},on:{"keydown":(event) => {
                _vm.handleArrowKey(event, 'mode', $index, -1);
                _vm.handlePlusMinusKey(event, 'mode', $index, -1);
                _vm.handleEnter(event, 'mode', $index, -1);
              }},model:{value:(entry.mode),callback:function ($$v) {_vm.$set(entry, "mode", $$v)},expression:"entry.mode"}})],1)]),_c('td',{staticClass:"dark-input"},[(_vm.rowCalculations[$index])?_c('i',{staticClass:"pi pi-check"}):_vm._e()])],2)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }