export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AccountNumber: any;
  BigInt: any;
  Byte: any;
  CountryCode: any;
  Cuid: any;
  Currency: any;
  DID: any;
  Date: any;
  DateTime: any;
  Duration: any;
  EmailAddress: any;
  GUID: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  Hexadecimal: any;
  IBAN: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  ISO8601Duration: any;
  JSON: any;
  JSONObject: any;
  JWT: any;
  Latitude: any;
  LocalDate: any;
  LocalEndTime: any;
  LocalTime: any;
  Locale: any;
  Long: any;
  Longitude: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonEmptyString: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  ObjectID: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  RoutingNumber: any;
  SafeInt: any;
  Time: any;
  TimeZone: any;
  Timestamp: any;
  URL: any;
  USCurrency: any;
  UUID: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  UtcOffset: any;
  Void: any;
};

export type Announcement = {
  createdAt: Scalars['Date'];
  id: Scalars['NonNegativeInt'];
  mode: Scalars['String'];
  text: Scalars['String'];
};

export type AnnouncementPayload = {
  appMode: AppMode;
  id?: InputMaybe<Scalars['NonNegativeInt']>;
  mode: Scalars['NonEmptyString'];
  text: Scalars['NonEmptyString'];
};

export enum AppMode {
  Dd = 'DD',
  Tt = 'TT'
}

export type AuditLog = {
  actionId: Scalars['String'];
  createdAt: Scalars['Date'];
  properties: Scalars['JSON'];
  type: LogType;
  userId: Scalars['String'];
};

export type BetSummary = {
  big: Scalars['Float'];
  small: Scalars['Float'];
};

export type CompanyBinBreakPayload = {
  firstPrizeBigPayout?: InputMaybe<Scalars['Float']>;
  firstPrizeSmallPayout?: InputMaybe<Scalars['Float']>;
  secondPrizeBigPayout?: InputMaybe<Scalars['Float']>;
  secondPrizeSmallPayout?: InputMaybe<Scalars['Float']>;
  thirdPrizeBigPayout?: InputMaybe<Scalars['Float']>;
  thirdPrizeSmallPayout?: InputMaybe<Scalars['Float']>;
};

export type CompanyReport = {
  /** TicketIn + Intake */
  companyTickets: CompanyStatement;
  /** companyTicket + outsetTickets */
  companyTicketsFinal: CompanyStatement;
  outsetTickets: CompanyStatement;
};

export type CompanyReportRow = {
  amount: Scalars['Float'];
  amountLessRebate: Scalars['Float'];
  big: Scalars['Float'];
  externalCompanyTicketRebate?: Maybe<Scalars['Float']>;
  externalShareClaim?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  net: Scalars['Float'];
  position: Scalars['Float'];
  rebate: Scalars['Float'];
  small: Scalars['Float'];
  strikeAmount: Scalars['Float'];
};

export type CompanyStatement = {
  rows: Array<CompanyReportRow>;
  summary: CompanyReportRow;
};

export type CopySettingPayload = {
  ddTargetUserIds: Array<Scalars['NonEmptyString']>;
  profileTargetUserIds: Array<Scalars['NonEmptyString']>;
  referenceUserId: Scalars['NonEmptyString'];
  ttTargetUserIds: Array<Scalars['NonEmptyString']>;
};

export type CreateExternalCompanyPayload = {
  bigLimit: Scalars['NonNegativeInt'];
  commission: Scalars['NonNegativeInt'];
  creditLimit: Scalars['NonNegativeInt'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  smallLimit: Scalars['NonNegativeInt'];
  timeClose: Scalars['String'];
  userId: Scalars['String'];
};

export type CreateUserPayload = {
  behalfOf: Scalars['NonEmptyString'];
  creditGiven: Scalars['NonNegativeFloat'];
  intakeType?: InputMaybe<IntakeType>;
  password: Scalars['NonEmptyString'];
  remarks: Scalars['String'];
  roleName: RoleName;
  userId: Scalars['NonEmptyString'];
  userPermissions: UserPermission;
  username: Scalars['NonEmptyString'];
};

export type CreditSummary = {
  betsInProgress: Scalars['Float'];
  creditBalance: Scalars['Float'];
  creditGiven: Scalars['Float'];
  creditGivenToDownlines: Scalars['Float'];
  creditUnpaid: Scalars['Float'];
  creditUsed: Scalars['Float'];
};

export type DdEntry = {
  big: Scalars['Float'];
  calculation: DdEntryCalculation;
  day: Scalars['Int'];
  mode?: Maybe<DdMode>;
  number: Scalars['String'];
  small: Scalars['Float'];
};

export type DdEntryCalculation = {
  bigCost: Scalars['Float'];
  bigCount: Scalars['Float'];
  rawCost: Scalars['Float'];
  smallCost: Scalars['Float'];
  smallCount: Scalars['Float'];
};

export type DdEntryPayload = {
  big: Scalars['NonNegativeFloat'];
  day: Scalars['NonNegativeInt'];
  mode?: InputMaybe<DdMode>;
  number: Scalars['NonEmptyString'];
  small: Scalars['NonNegativeFloat'];
};

export type DdEntryRaw = {
  big: Scalars['Float'];
  intakes: Array<Intake>;
  number: Scalars['String'];
  release: PageRelease;
  small: Scalars['Float'];
};

export enum DdMode {
  R = 'R',
  I = 'i',
  K = 'k'
}

export type DdOutset = {
  confirmedAt?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  entries: Array<Scalars['JSON']>;
  externalCompanyId: Scalars['String'];
  fileName: Scalars['String'];
  id: Scalars['String'];
  page: Scalars['String'];
  readyAt?: Maybe<Scalars['Date']>;
};

export type DdPage = {
  calculation: DdEntryCalculation;
  createdAt: Scalars['Date'];
  enteredById: Scalars['String'];
  enteredFrom: EntrySource;
  entries: Array<Scalars['JSON']>;
  id: Scalars['String'];
  name: Scalars['String'];
  releaseDate: Scalars['Date'];
  /** return all releases relevant for page */
  releases: Array<PageRelease>;
  summary: DdEntryCalculation;
  userId: Scalars['String'];
  username?: Maybe<Scalars['String']>;
};


export type DdPageEntriesArgs = {
  releaseDates?: InputMaybe<Array<Scalars['Date']>>;
};


export type DdPageSummaryArgs = {
  releaseDates?: InputMaybe<Array<Scalars['Date']>>;
};

export type DdPagePayload = {
  entries: Array<DdEntryPayload>;
  entrySource: EntrySource;
  page: Scalars['String'];
  userId: Scalars['NonEmptyString'];
};

export type DdResult = {
  consolation1?: Maybe<Scalars['String']>;
  consolation2?: Maybe<Scalars['String']>;
  consolation3?: Maybe<Scalars['String']>;
  consolation4?: Maybe<Scalars['String']>;
  consolation5?: Maybe<Scalars['String']>;
  consolation6?: Maybe<Scalars['String']>;
  consolation7?: Maybe<Scalars['String']>;
  consolation8?: Maybe<Scalars['String']>;
  consolation9?: Maybe<Scalars['String']>;
  consolation10?: Maybe<Scalars['String']>;
  firstPrize?: Maybe<Scalars['String']>;
  secondPrize?: Maybe<Scalars['String']>;
  starter1?: Maybe<Scalars['String']>;
  starter2?: Maybe<Scalars['String']>;
  starter3?: Maybe<Scalars['String']>;
  starter4?: Maybe<Scalars['String']>;
  starter5?: Maybe<Scalars['String']>;
  starter6?: Maybe<Scalars['String']>;
  starter7?: Maybe<Scalars['String']>;
  starter8?: Maybe<Scalars['String']>;
  starter9?: Maybe<Scalars['String']>;
  starter10?: Maybe<Scalars['String']>;
  thirdPrize?: Maybe<Scalars['String']>;
};

export type DdStrikeConfig = {
  bigPayout: Scalars['Float'];
  smallPayout: Scalars['Float'];
  strikeType: DdStrikeType;
};

export type DdResultsPayload = {
  consolation1?: InputMaybe<Scalars['String']>;
  consolation2?: InputMaybe<Scalars['String']>;
  consolation3?: InputMaybe<Scalars['String']>;
  consolation4?: InputMaybe<Scalars['String']>;
  consolation5?: InputMaybe<Scalars['String']>;
  consolation6?: InputMaybe<Scalars['String']>;
  consolation7?: InputMaybe<Scalars['String']>;
  consolation8?: InputMaybe<Scalars['String']>;
  consolation9?: InputMaybe<Scalars['String']>;
  consolation10?: InputMaybe<Scalars['String']>;
  firstPrize?: InputMaybe<Scalars['String']>;
  secondPrize?: InputMaybe<Scalars['String']>;
  starter1?: InputMaybe<Scalars['String']>;
  starter2?: InputMaybe<Scalars['String']>;
  starter3?: InputMaybe<Scalars['String']>;
  starter4?: InputMaybe<Scalars['String']>;
  starter5?: InputMaybe<Scalars['String']>;
  starter6?: InputMaybe<Scalars['String']>;
  starter7?: InputMaybe<Scalars['String']>;
  starter8?: InputMaybe<Scalars['String']>;
  starter9?: InputMaybe<Scalars['String']>;
  starter10?: InputMaybe<Scalars['String']>;
  thirdPrize?: InputMaybe<Scalars['String']>;
};

export enum DdStrikeType {
  Consolation1 = 'consolation1',
  Consolation2 = 'consolation2',
  Consolation3 = 'consolation3',
  Consolation4 = 'consolation4',
  Consolation5 = 'consolation5',
  Consolation6 = 'consolation6',
  Consolation7 = 'consolation7',
  Consolation8 = 'consolation8',
  Consolation9 = 'consolation9',
  Consolation10 = 'consolation10',
  FirstPrize = 'firstPrize',
  SecondPrize = 'secondPrize',
  Starter1 = 'starter1',
  Starter2 = 'starter2',
  Starter3 = 'starter3',
  Starter4 = 'starter4',
  Starter5 = 'starter5',
  Starter6 = 'starter6',
  Starter7 = 'starter7',
  Starter8 = 'starter8',
  Starter9 = 'starter9',
  Starter10 = 'starter10',
  ThirdPrize = 'thirdPrize'
}

export type DeletedDdPage = {
  calculation: DdEntryCalculation;
  createdAt: Scalars['Date'];
  deletedAt: Scalars['Date'];
  deletedById: Scalars['String'];
  enteredById: Scalars['String'];
  enteredFrom: EntrySource;
  entries: Array<Scalars['JSON']>;
  id: Scalars['String'];
  name: Scalars['String'];
  userId: Scalars['String'];
  username: Scalars['String'];
};

export type DeletedTtPage = {
  calculation: TtEntryCalculation;
  createdAt: Scalars['Date'];
  deletedAt: Scalars['Date'];
  deletedById: Scalars['String'];
  enteredById: Scalars['String'];
  enteredFrom: EntrySource;
  entries: Array<Scalars['JSON']>;
  id: Scalars['String'];
  name: Scalars['String'];
  userId: Scalars['String'];
  username: Scalars['String'];
};

export type DownlineIntake = {
  intakerUserId: Scalars['String'];
  intakerUsername: Scalars['String'];
  rows: Array<Maybe<Scalars['JSON']>>;
};

export type DrawWindow = {
  /** moment.duration().toISOString() */
  close: Scalars['String'];
  reopen: Scalars['String'];
};

export type DrawWindowSetting = {
  close: Scalars['String'];
  reopen: Scalars['String'];
};

export enum EntrySource {
  Fixed = 'fixed',
  MassEntry = 'massEntry',
  Saved = 'saved',
  Telegram = 'telegram',
  Upload = 'upload'
}

export type ExternalCompany = {
  bigLimit: Scalars['Int'];
  commission: Scalars['Int'];
  creditLimit: Scalars['Int'];
  id: Scalars['String'];
  loginId: Scalars['String'];
  loginPassword: Scalars['String'];
  name: Scalars['String'];
  outsetClaim: OutsetClaim;
  outsetSummary: OutsetSummary;
  priority: Scalars['Int'];
  smallLimit: Scalars['Int'];
  timeClose: Scalars['String'];
  ttOutsetClaim: Array<TtOutsetClaim>;
  ttOutsetSummary: TtOutsetSummary;
  unitLimit: Scalars['Int'];
  userId: Scalars['String'];
};


export type ExternalCompanyOutsetClaimArgs = {
  releaseDate: Scalars['Date'];
};


export type ExternalCompanyOutsetSummaryArgs = {
  releaseDate: Scalars['Date'];
};


export type ExternalCompanyTtOutsetClaimArgs = {
  releaseDate: Scalars['Date'];
};


export type ExternalCompanyTtOutsetSummaryArgs = {
  releaseDate: Scalars['Date'];
};

export type FixedBet = {
  calculate: Scalars['String'];
  close: Scalars['String'];
};

export type FixedBetWindowSetting = {
  calculate: Scalars['String'];
  close: Scalars['String'];
};

export type GameSettingPayload = {
  behalfOf: Scalars['NonEmptyString'];
  ddCompanyPl: Scalars['NonNegativeFloat'];
  ddConsolationPrizeCommission: Scalars['NonNegativeFloat'];
  ddDownlineIntake: Scalars['Boolean'];
  ddEnabled: Scalars['Boolean'];
  ddFirstPrizeCommission: Scalars['NonNegativeFloat'];
  ddIntake: Scalars['Boolean'];
  ddIntakeBig: Scalars['NonNegativeFloat'];
  ddIntakeMethod: IntakeMethod;
  ddIntakeSmall: Scalars['NonNegativeFloat'];
  ddIntakeTax: Scalars['NonNegativeFloat'];
  ddManagementExpenses?: InputMaybe<Scalars['NonNegativeFloat']>;
  ddPlaceoutPl: Scalars['NonNegativeFloat'];
  ddSecondPrizeCommission: Scalars['NonNegativeFloat'];
  ddStarterPrizeCommission: Scalars['NonNegativeFloat'];
  ddThirdPrizeCommission: Scalars['NonNegativeFloat'];
  ddTicketRebate: Scalars['NonNegativeFloat'];
  ddUplineIntakePl: Scalars['NonNegativeFloat'];
};

export type Intake = {
  big: Scalars['Float'];
  id: Scalars['String'];
  number: Scalars['String'];
  small: Scalars['Float'];
  turn: Scalars['Float'];
  userId: Scalars['String'];
};

export enum IntakeMethod {
  BigSmall = 'bigSmall',
  EatBigTurnSmall = 'eatBigTurnSmall',
  PriorityBig = 'priorityBig',
  PrioritySmall = 'prioritySmall'
}

export type IntakeSettingPayload = {
  ddIntakeBig: Scalars['NonNegativeFloat'];
  ddIntakeMethod: IntakeMethod;
  ddIntakeSmall: Scalars['NonNegativeFloat'];
};

export enum IntakeType {
  Layer = 'layer',
  Shadow = 'shadow',
  Stack = 'stack'
}

export enum JobState {
  Active = 'active',
  Completed = 'completed',
  Delayed = 'delayed',
  Failed = 'failed',
  Paused = 'paused',
  Stuck = 'stuck',
  Unknown = 'unknown',
  Waiting = 'waiting'
}

export enum LanguageLocale {
  En = 'en',
  Zh = 'zh'
}

export enum LogType {
  Read = 'read',
  Write = 'write'
}

export type LoginHistoryLog = {
  actionId: Scalars['String'];
  createdAt: Scalars['Date'];
  properties: Scalars['JSON'];
  userId: Scalars['String'];
};

export type LoginResponse = {
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type MobileSettingPayload = {
  behalfOf: Scalars['NonEmptyString'];
  mobile1: Scalars['String'];
  mobile2: Scalars['String'];
  mobile3: Scalars['String'];
};

export type Mutation = {
  /** Password must be at least 6 characters and at most 30 characters */
  changePassword: Scalars['Boolean'];
  clearReports: Scalars['Boolean'];
  clearTtReports: Scalars['Boolean'];
  copySetting: Scalars['Boolean'];
  createExternalCompany: ExternalCompany;
  createOutset: Scalars['Boolean'];
  createTtOutset: Scalars['Boolean'];
  /** Return auto generated password */
  createUser: Scalars['String'];
  deleteAnnouncement: Announcement;
  deleteDdPage: Scalars['Boolean'];
  deleteDdPageEntries: Scalars['Boolean'];
  deleteExternalCompany: ExternalCompany;
  deleteOutsets: Scalars['Boolean'];
  deletePayment: Scalars['Boolean'];
  deleteSavedDdPage: Scalars['Boolean'];
  deleteSavedTtPage: Scalars['Boolean'];
  deleteTtOutsets: Scalars['Boolean'];
  deleteTtPage: Scalars['Boolean'];
  deleteTtPageEntries: Scalars['Boolean'];
  doOutsetLinking: Scalars['Boolean'];
  doTtOutsetLinking: Scalars['Boolean'];
  generateReports: Scalars['Boolean'];
  generateTtReports: Scalars['Boolean'];
  insertDdEntry: Scalars['String'];
  insertTtEntry: Scalars['String'];
  linkEntry: Scalars['Boolean'];
  linkFetch: Scalars['Boolean'];
  login: LoginResponse;
  refreshAccessToken: RefreshAccessTokenResponse;
  renameDdPage: Scalars['Boolean'];
  renameTtPage: Scalars['Boolean'];
  saveDdEntry: Scalars['String'];
  saveTtEntry: Scalars['String'];
  setOutsetStatus: Scalars['Boolean'];
  setTtOutsetStatus: Scalars['Boolean'];
  syncDdResults: Scalars['Boolean'];
  syncTtResults: Scalars['Boolean'];
  updateBinBreak: Scalars['Boolean'];
  updateCompanyIntake: Scalars['Boolean'];
  updateExpenses: Scalars['Boolean'];
  updateExternalCompany: ExternalCompany;
  updateGameSetting: Scalars['String'];
  updateMobileSetting: Scalars['String'];
  updateNickname: Scalars['String'];
  updateOutsetClaim: Scalars['Boolean'];
  updateOwnIntake: Scalars['String'];
  updateOwnTtIntake: Scalars['String'];
  updatePayment: Scalars['String'];
  updateReleaseDay?: Maybe<Scalars['Date']>;
  updateReleaseLock: Scalars['Boolean'];
  updateScrollingText: Scalars['String'];
  updateSetting: Scalars['String'];
  updateTelegramBotName: Scalars['String'];
  updateTnc: Scalars['String'];
  updateTtCompanyIntake: Scalars['Boolean'];
  updateTtGameSetting: Scalars['String'];
  updateTtMaximumStake: Scalars['Boolean'];
  updateTtOutsetClaim: Scalars['Boolean'];
  updateUser: Scalars['String'];
  updateUserCredit: Scalars['String'];
  updateWindowSetting: Scalars['Boolean'];
  upsertAnnouncement: Announcement;
  upsertDdResults: DdResult;
  upsertTtResults: TtResult;
};


export type MutationChangePasswordArgs = {
  behalfOf: Scalars['NonEmptyString'];
  newPassword: Scalars['NonEmptyString'];
};


export type MutationClearReportsArgs = {
  releaseDate: Scalars['Date'];
};


export type MutationClearTtReportsArgs = {
  releaseDate: Scalars['Date'];
};


export type MutationCopySettingArgs = {
  payload: CopySettingPayload;
};


export type MutationCreateExternalCompanyArgs = {
  payload: CreateExternalCompanyPayload;
};


export type MutationCreateOutsetArgs = {
  payload: OutsetPayload;
  releaseDate: Scalars['Date'];
};


export type MutationCreateTtOutsetArgs = {
  payload: TtOutsetPayload;
  releaseDate: Scalars['Date'];
};


export type MutationCreateUserArgs = {
  payload: CreateUserPayload;
};


export type MutationDeleteAnnouncementArgs = {
  id: Scalars['NonNegativeInt'];
};


export type MutationDeleteDdPageArgs = {
  id: Scalars['UUID'];
  releaseDates: Array<Scalars['Date']>;
};


export type MutationDeleteDdPageEntriesArgs = {
  entryIds: Array<Scalars['UUID']>;
  id: Scalars['UUID'];
  releaseDates: Array<Scalars['Date']>;
};


export type MutationDeleteExternalCompanyArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteOutsetsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeletePaymentArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteSavedDdPageArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteSavedTtPageArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteTtOutsetsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteTtPageArgs = {
  id: Scalars['String'];
  releaseDates: Array<Scalars['Date']>;
};


export type MutationDeleteTtPageEntriesArgs = {
  entryIds: Array<Scalars['UUID']>;
  id: Scalars['UUID'];
  releaseDates: Array<Scalars['Date']>;
};


export type MutationDoOutsetLinkingArgs = {
  releaseDate: Scalars['Date'];
};


export type MutationDoTtOutsetLinkingArgs = {
  releaseDate: Scalars['Date'];
};


export type MutationGenerateReportsArgs = {
  releaseDate: Scalars['Date'];
};


export type MutationGenerateTtReportsArgs = {
  releaseDate: Scalars['Date'];
};


export type MutationInsertDdEntryArgs = {
  page: DdPagePayload;
};


export type MutationInsertTtEntryArgs = {
  page: TtPagePayload;
};


export type MutationLinkEntryArgs = {
  releaseDate: Scalars['Date'];
};


export type MutationLinkFetchArgs = {
  releaseDate: Scalars['Date'];
};


export type MutationLoginArgs = {
  password: Scalars['NonEmptyString'];
  userId: Scalars['NonEmptyString'];
};


export type MutationRefreshAccessTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationRenameDdPageArgs = {
  id: Scalars['String'];
  newName: Scalars['String'];
};


export type MutationRenameTtPageArgs = {
  id: Scalars['String'];
  newName: Scalars['String'];
};


export type MutationSaveDdEntryArgs = {
  page: SaveDdPagePayload;
};


export type MutationSaveTtEntryArgs = {
  page: SaveTtPagePayload;
};


export type MutationSetOutsetStatusArgs = {
  ids: Array<Scalars['UUID']>;
  status: OutsetStatus;
};


export type MutationSetTtOutsetStatusArgs = {
  ids: Array<Scalars['UUID']>;
  status: OutsetStatus;
};


export type MutationSyncDdResultsArgs = {
  releaseDate: Scalars['Date'];
};


export type MutationSyncTtResultsArgs = {
  releaseDate: Scalars['Date'];
};


export type MutationUpdateBinBreakArgs = {
  autoCalculateBinBreak?: InputMaybe<Scalars['Boolean']>;
  company: CompanyBinBreakPayload;
  outsetRows: Array<OutsetBinBreakPayload>;
  releaseDate: Scalars['Date'];
};


export type MutationUpdateCompanyIntakeArgs = {
  big: Scalars['NonNegativeInt'];
  releaseDate: Scalars['Date'];
  small: Scalars['NonNegativeInt'];
};


export type MutationUpdateExpensesArgs = {
  fixedExpenses: Scalars['NonNegativeFloat'];
  releaseDate: Scalars['Date'];
  variableExpenses: Scalars['NonNegativeFloat'];
};


export type MutationUpdateExternalCompanyArgs = {
  payload: UpdateExternalCompanyPayload;
};


export type MutationUpdateGameSettingArgs = {
  payload: GameSettingPayload;
};


export type MutationUpdateMobileSettingArgs = {
  payload: MobileSettingPayload;
};


export type MutationUpdateNicknameArgs = {
  nickname: Scalars['NonEmptyString'];
};


export type MutationUpdateOutsetClaimArgs = {
  outsetRows: Array<OutsetClaimPayload>;
  releaseDate: Scalars['Date'];
};


export type MutationUpdateOwnIntakeArgs = {
  payload: IntakeSettingPayload;
};


export type MutationUpdateOwnTtIntakeArgs = {
  payload: TtIntakeSettingPayload;
};


export type MutationUpdatePaymentArgs = {
  payload: PaymentPayload;
};


export type MutationUpdateReleaseDayArgs = {
  newReleaseDate: Scalars['Date'];
  releaseDate: Scalars['Date'];
};


export type MutationUpdateReleaseLockArgs = {
  flag: Scalars['Boolean'];
  releaseDate: Scalars['Date'];
};


export type MutationUpdateScrollingTextArgs = {
  appMode: AppMode;
  value: Scalars['String'];
};


export type MutationUpdateSettingArgs = {
  key: Scalars['String'];
  value: Scalars['String'];
};


export type MutationUpdateTelegramBotNameArgs = {
  appMode: AppMode;
  value: Scalars['String'];
};


export type MutationUpdateTncArgs = {
  value: Scalars['String'];
};


export type MutationUpdateTtCompanyIntakeArgs = {
  companyIntakeD1: Scalars['NonNegativeInt'];
  companyIntakeD2: Scalars['NonNegativeInt'];
  companyIntakeD3: Scalars['NonNegativeInt'];
  companyIntakeD4: Scalars['NonNegativeInt'];
  releaseDate: Scalars['Date'];
};


export type MutationUpdateTtGameSettingArgs = {
  payload: TtGameSettingPayload;
};


export type MutationUpdateTtMaximumStakeArgs = {
  releaseDate: Scalars['Date'];
  ttMaximumStake: Scalars['NonNegativeInt'];
};


export type MutationUpdateTtOutsetClaimArgs = {
  outsetRows: Array<TtOutsetClaimPayload>;
  releaseDate: Scalars['Date'];
};


export type MutationUpdateUserArgs = {
  payload: UpdateUserPayload;
};


export type MutationUpdateUserCreditArgs = {
  payload: UpdateUserCreditPayload;
};


export type MutationUpdateWindowSettingArgs = {
  appMode: AppMode;
  payload: WindowSettingPayload;
};


export type MutationUpsertAnnouncementArgs = {
  announcement: AnnouncementPayload;
};


export type MutationUpsertDdResultsArgs = {
  payload: DdResultsPayload;
  releaseDate: Scalars['Date'];
};


export type MutationUpsertTtResultsArgs = {
  payload: TtResultsPayload;
  releaseDate: Scalars['Date'];
};

export type NumberStake = {
  in: Stake;
  intake: Stake;
  number: Scalars['String'];
  toCompany: Stake;
  userId: Scalars['String'];
};

export type OutsetBinBreak = {
  bigPayout: Scalars['Float'];
  smallPayout: Scalars['Float'];
  strikeType: DdStrikeType;
};

export type OutsetBinBreakPayload = {
  companyId: Scalars['String'];
  firstPrizeBigPayout?: InputMaybe<Scalars['Float']>;
  firstPrizeSmallPayout?: InputMaybe<Scalars['Float']>;
  secondPrizeBigPayout?: InputMaybe<Scalars['Float']>;
  secondPrizeSmallPayout?: InputMaybe<Scalars['Float']>;
  thirdPrizeBigPayout?: InputMaybe<Scalars['Float']>;
  thirdPrizeSmallPayout?: InputMaybe<Scalars['Float']>;
};

export type OutsetClaim = {
  outsetBinBreaks: Array<OutsetBinBreak>;
  position: Scalars['Float'];
  shareClaim: Scalars['Float'];
};

export type OutsetClaimPayload = {
  externalShareClaim: Scalars['Float'];
  id: Scalars['String'];
  position: Scalars['Float'];
};

export type OutsetEntryPayload = {
  big: Scalars['NonNegativeInt'];
  number: Scalars['String'];
  small: Scalars['NonNegativeInt'];
};

export type OutsetPayload = {
  entries: Array<OutsetEntryPayload>;
  externalCompanyIds: Array<Scalars['UUID']>;
};

export enum OutsetStatus {
  Confirmed = 'confirmed',
  Pending = 'pending',
  Ready = 'ready'
}

export type OutsetStrikeNumberReport = {
  releaseDate: Scalars['Date'];
  rows: Array<OutsetStrikeNumberReportRow>;
};

export type OutsetStrikeNumberReportRow = {
  big: Scalars['Float'];
  number: Scalars['String'];
  page: Scalars['String'];
  small: Scalars['Float'];
  strikeAmount: Scalars['Float'];
  strikeType: DdStrikeType;
  time: Scalars['Date'];
  userId: Scalars['String'];
};

export type OutsetSummary = {
  amount: Scalars['Float'];
  big: Scalars['Float'];
  small: Scalars['Float'];
};

export type PageRelease = {
  id: Scalars['String'];
  isOver: Scalars['Boolean'];
  locked: Scalars['Boolean'];
  releaseDate: Scalars['Date'];
};

export type Payment = {
  amount: Scalars['Float'];
  createdAt: Scalars['Date'];
  createdByUserId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  payeeUserId: Scalars['String'];
  paymentType: PaymentType;
  payorUserId: Scalars['String'];
  releaseDate?: Maybe<Scalars['Date']>;
  remark?: Maybe<Scalars['String']>;
};

export type PaymentInput = {
  amount: Scalars['Float'];
  paymentType: PaymentType;
  payorUserId: Scalars['String'];
  remark?: InputMaybe<Scalars['String']>;
};

export type PaymentPayload = {
  payments: Array<PaymentInput>;
};

export enum PaymentType {
  DdBet = 'ddBet',
  DdOutsetClaim = 'ddOutsetClaim',
  DdPositionShare = 'ddPositionShare',
  DdStrikePayout = 'ddStrikePayout',
  OtherNegative = 'otherNegative',
  OtherPositive = 'otherPositive',
  PaymentReceived = 'paymentReceived',
  StrikePayout = 'strikePayout',
  TtBet = 'ttBet',
  TtBetD1 = 'ttBetD1',
  TtBetD2 = 'ttBetD2',
  TtBetD3 = 'ttBetD3',
  TtBetD4 = 'ttBetD4',
  TtOutsetClaim = 'ttOutsetClaim',
  TtOutsetClaimD1 = 'ttOutsetClaimD1',
  TtOutsetClaimD2 = 'ttOutsetClaimD2',
  TtOutsetClaimD3 = 'ttOutsetClaimD3',
  TtOutsetClaimD4 = 'ttOutsetClaimD4',
  TtPositionShare = 'ttPositionShare',
  TtPositionShareD1 = 'ttPositionShareD1',
  TtPositionShareD2 = 'ttPositionShareD2',
  TtPositionShareD3 = 'ttPositionShareD3',
  TtPositionShareD4 = 'ttPositionShareD4',
  TtStrikePayout = 'ttStrikePayout',
  TtStrikePayoutD1 = 'ttStrikePayoutD1',
  TtStrikePayoutD2 = 'ttStrikePayoutD2',
  TtStrikePayoutD3 = 'ttStrikePayoutD3',
  TtStrikePayoutD4 = 'ttStrikePayoutD4'
}

export type PositionStatement = {
  contributeAmount: Scalars['Float'];
  numCount: Scalars['Int'];
  positionType: PositionType;
  releaseDate: Scalars['Date'];
  uplineId?: Maybe<Scalars['String']>;
  uplineIntakeAmount: Scalars['Float'];
  uplineIntakeNet: Scalars['Float'];
  uplinePlFight: Scalars['Float'];
  uplinePosition: Scalars['Float'];
  uplineStake: Scalars['Float'];
};

export enum PositionType {
  Company = 'company',
  Outset = 'outset',
  Upline = 'upline'
}

export type ProfileChange = {
  close: Scalars['String'];
  reopen: Scalars['String'];
};

export type ProfileChangeWindowSetting = {
  close: Scalars['String'];
  reopen: Scalars['String'];
};

export type Query = {
  announcements: Array<Announcement>;
  auditLogs: Array<AuditLog>;
  companyReport: CompanyReport;
  deletedPage: DeletedDdPage;
  deletedTtPage: DeletedTtPage;
  externalCompanies: Array<ExternalCompany>;
  fullReport: UserReport;
  isProfileChangeWindowOpen: Scalars['Boolean'];
  loginHistory: Array<LoginHistoryLog>;
  me?: Maybe<User>;
  nextDownlineUsername: Scalars['String'];
  numberStakes: Array<NumberStake>;
  page: DdPage;
  releases: Array<Release>;
  scrollingText: Scalars['String'];
  stakeFile: StakeFiles;
  strikeNumberOutsetReports: Array<OutsetStrikeNumberReport>;
  strikeNumberReports: Array<StrikeNumberReport>;
  systemTime: Scalars['String'];
  telegramBotName: Scalars['String'];
  tnc: Scalars['String'];
  ttCompanyReport: TtCompanyReport;
  ttFullReport: TtUserReport;
  ttNumberStakes: Array<TtNumberStake>;
  ttPage: TtPage;
  ttStakeFile: TtStakeFiles;
  ttStrikeNumberOutsetReports: Array<TtOutsetStrikeNumberReport>;
  ttStrikeNumberReports: Array<TtStrikeNumberReport>;
  ttStrikeSummaryReports: Array<TtStrikeSummaryReport>;
  ttUserStakes: UserStakes;
  user?: Maybe<User>;
  userStakes: UserStakes;
  windowSettings: WindowSettings;
};


export type QueryAnnouncementsArgs = {
  appMode: AppMode;
};


export type QueryAuditLogsArgs = {
  date?: InputMaybe<Scalars['Date']>;
};


export type QueryCompanyReportArgs = {
  releaseDateEnd: Scalars['Date'];
  releaseDateStart: Scalars['Date'];
};


export type QueryDeletedPageArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};


export type QueryDeletedTtPageArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};


export type QueryFullReportArgs = {
  releaseDateEnd: Scalars['Date'];
  releaseDateStart: Scalars['Date'];
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryIsProfileChangeWindowOpenArgs = {
  appMode: AppMode;
  userId: Scalars['String'];
};


export type QueryLoginHistoryArgs = {
  date?: InputMaybe<Scalars['Date']>;
};


export type QueryNextDownlineUsernameArgs = {
  targetRole: RoleName;
};


export type QueryNumberStakesArgs = {
  appMode?: InputMaybe<AppMode>;
  behalfOf: Scalars['String'];
  number: Scalars['String'];
  releaseDate: Scalars['Date'];
};


export type QueryPageArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};


export type QueryReleasesArgs = {
  appMode?: InputMaybe<AppMode>;
  dateRange?: InputMaybe<ReleaseDateRangeInput>;
  weeksBackCount?: InputMaybe<Scalars['NonNegativeInt']>;
};


export type QueryScrollingTextArgs = {
  appMode: AppMode;
};


export type QueryStakeFileArgs = {
  live?: InputMaybe<Scalars['Boolean']>;
  releaseDate: Scalars['Date'];
};


export type QueryStrikeNumberOutsetReportsArgs = {
  releaseDateEnd: Scalars['Date'];
  releaseDateStart: Scalars['Date'];
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryStrikeNumberReportsArgs = {
  releaseDateEnd: Scalars['Date'];
  releaseDateStart: Scalars['Date'];
  statementType?: InputMaybe<StatementType>;
  userId: Scalars['String'];
};


export type QueryTelegramBotNameArgs = {
  appMode: AppMode;
};


export type QueryTtCompanyReportArgs = {
  numCount?: InputMaybe<Scalars['Int']>;
  releaseDateEnd: Scalars['Date'];
  releaseDateStart: Scalars['Date'];
};


export type QueryTtFullReportArgs = {
  releaseDateEnd: Scalars['Date'];
  releaseDateStart: Scalars['Date'];
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryTtNumberStakesArgs = {
  behalfOf: Scalars['String'];
  number: Scalars['String'];
  releaseDate: Scalars['Date'];
};


export type QueryTtPageArgs = {
  id?: InputMaybe<Scalars['UUID']>;
};


export type QueryTtStakeFileArgs = {
  live?: InputMaybe<Scalars['Boolean']>;
  releaseDate: Scalars['Date'];
};


export type QueryTtStrikeNumberOutsetReportsArgs = {
  releaseDateEnd: Scalars['Date'];
  releaseDateStart: Scalars['Date'];
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryTtStrikeNumberReportsArgs = {
  releaseDateEnd: Scalars['Date'];
  releaseDateStart: Scalars['Date'];
  statementType?: InputMaybe<StatementType>;
  userId: Scalars['String'];
};


export type QueryTtStrikeSummaryReportsArgs = {
  releaseDateEnd: Scalars['Date'];
  releaseDateStart: Scalars['Date'];
};


export type QueryTtUserStakesArgs = {
  behalfOf: Scalars['String'];
  releaseDate: Scalars['Date'];
};


export type QueryUserArgs = {
  userId: Scalars['String'];
};


export type QueryUserStakesArgs = {
  behalfOf: Scalars['String'];
  releaseDate: Scalars['Date'];
};


export type QueryWindowSettingsArgs = {
  appMode: AppMode;
};

export type RefreshAccessTokenResponse = {
  accessToken?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Release = {
  appMode: AppMode;
  archived: Scalars['Boolean'];
  autoCalculateBinBreak: Scalars['Boolean'];
  companyIntakeBig: Scalars['Int'];
  companyIntakeD1: Scalars['Int'];
  companyIntakeD2: Scalars['Int'];
  companyIntakeD3: Scalars['Int'];
  companyIntakeD4: Scalars['Int'];
  companyIntakeSmall: Scalars['Int'];
  ddResults?: Maybe<DdResult>;
  ddStrikeConfig: Array<DdStrikeConfig>;
  fixedBetDone: Scalars['Boolean'];
  fixedExpenses: Scalars['Float'];
  generateReportState?: Maybe<JobState>;
  id: Scalars['String'];
  isOver: Scalars['Boolean'];
  locked: Scalars['Boolean'];
  outsetClaimUpdated: Scalars['Boolean'];
  outsetLinkingDone: Scalars['Boolean'];
  outsets: Array<DdOutset>;
  releaseDate: Scalars['Date'];
  releaseDay: Scalars['Int'];
  reportsGenerated: Scalars['Boolean'];
  resultsComplete: Scalars['Boolean'];
  strikeByNumberReport: Array<StrikeNumberReportRow>;
  strikeSummaryReports: Array<StrikeSummaryReportRow>;
  ttMaximumStake: Scalars['Int'];
  ttOutsets: Array<TtOutset>;
  ttResults?: Maybe<TtResult>;
  ttStrikeByNumberReport: Array<TtStrikeNumberReportRow>;
  ttStrikeSummaryReports: Array<TtStrikeSummaryReportRow>;
  variableExpenses: Scalars['Float'];
  windowCloseAt: Scalars['Date'];
  windowIsOpen: Scalars['Boolean'];
  windowOpenAt: Scalars['Date'];
};

export type ReleaseDateRangeInput = {
  releaseDateEnd: Scalars['Date'];
  releaseDateStart: Scalars['Date'];
};

export enum RoleName {
  Admin = 'admin',
  Agent = 'agent',
  Manager = 'manager',
  Member = 'member'
}

export type SaveDdPagePayload = {
  entries: Array<DdEntryPayload>;
  id?: InputMaybe<Scalars['UUID']>;
  page: Scalars['String'];
  storedPageType: StoredPageType;
  uploadMeta?: InputMaybe<UploadMeta>;
  userId: Scalars['NonEmptyString'];
};

export type SaveTtPagePayload = {
  entries: Array<TtEntryPayload>;
  id?: InputMaybe<Scalars['UUID']>;
  page: Scalars['String'];
  storedPageType: StoredPageType;
  uploadMeta?: InputMaybe<UploadMeta>;
  userId: Scalars['NonEmptyString'];
};

export type Stake = {
  amount: Scalars['Float'];
  big: Scalars['Float'];
  small: Scalars['Float'];
};

export type StakeFiles = {
  companyFinalStake: Array<Maybe<Scalars['JSON']>>;
  downlineEatStake: Array<Maybe<Scalars['JSON']>>;
  forOutsetStake: Array<Maybe<Scalars['JSON']>>;
  masterStake: Array<Maybe<Scalars['JSON']>>;
  outsetStake: Array<Maybe<Scalars['JSON']>>;
};

export enum StatementType {
  Intake = 'intake',
  Self = 'self',
  TicketIn = 'ticketIn'
}

export enum StatusEnum {
  Live = 'live',
  Lock = 'lock'
}

export type StoredDdPage = {
  createdAt: Scalars['Date'];
  enteredById: Scalars['String'];
  entries: Array<Maybe<Scalars['JSON']>>;
  id: Scalars['String'];
  name: Scalars['String'];
  storedPageType: StoredPageType;
  uploadFileName?: Maybe<Scalars['String']>;
  uploadFileSize?: Maybe<Scalars['Int']>;
  userId: Scalars['String'];
  username: Scalars['String'];
};

export enum StoredPageType {
  Fixed = 'fixed',
  Saved = 'saved',
  Upload = 'upload'
}

export type StoredTtPage = {
  createdAt: Scalars['Date'];
  enteredById: Scalars['String'];
  entries: Array<Maybe<Scalars['JSON']>>;
  id: Scalars['String'];
  name: Scalars['String'];
  storedPageType: StoredPageType;
  uploadFileName?: Maybe<Scalars['String']>;
  uploadFileSize?: Maybe<Scalars['Int']>;
  userId: Scalars['String'];
  username: Scalars['String'];
};

export type StrikeNumberReport = {
  releaseDate: Scalars['Date'];
  rows: Array<StrikeNumberReportRow>;
};

export type StrikeNumberReportRow = {
  big: Scalars['Float'];
  entrySource: EntrySource;
  number: Scalars['String'];
  page: Scalars['String'];
  small: Scalars['Float'];
  strikeAmount: Scalars['Float'];
  strikeType: DdStrikeType;
  time: Scalars['Date'];
  userId: Scalars['String'];
};

export type StrikeSummaryReportRow = {
  companyAmount: Scalars['Float'];
  companyBig: Scalars['Float'];
  companySmall: Scalars['Float'];
  number: Scalars['String'];
  outsetAmount: Scalars['Float'];
  strikeType: DdStrikeType;
};

export type SummaryReportRow = {
  amount?: Maybe<Scalars['Float']>;
  amountLessRebate?: Maybe<Scalars['Float']>;
  date: Scalars['Date'];
  description: Scalars['String'];
  net?: Maybe<Scalars['Float']>;
  netBal?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  rebate?: Maybe<Scalars['Float']>;
  shareClaim?: Maybe<Scalars['Float']>;
  strikeAmount?: Maybe<Scalars['Float']>;
  strikeCommission?: Maybe<Scalars['Float']>;
};

export type TtEntryCalculation = {
  unit: Scalars['Float'];
};

export type TtEntryPayload = {
  day: Scalars['NonNegativeInt'];
  mode?: InputMaybe<TtMode>;
  number1: Scalars['String'];
  number2?: InputMaybe<Scalars['String']>;
  number3?: InputMaybe<Scalars['String']>;
  number4?: InputMaybe<Scalars['String']>;
  number5?: InputMaybe<Scalars['String']>;
  number6?: InputMaybe<Scalars['String']>;
  number7?: InputMaybe<Scalars['String']>;
  number8?: InputMaybe<Scalars['String']>;
  number9?: InputMaybe<Scalars['String']>;
  number10?: InputMaybe<Scalars['String']>;
  unit: Scalars['NonNegativeFloat'];
};

export type TtGameSettingPayload = {
  behalfOf: Scalars['NonEmptyString'];
  ttD1CompanyPl: Scalars['NonNegativeFloat'];
  ttD1Payout: Scalars['NonNegativeFloat'];
  ttD1PlaceoutPl: Scalars['NonNegativeFloat'];
  ttD1TicketRebate: Scalars['NonNegativeFloat'];
  ttD1UplinePl: Scalars['NonNegativeFloat'];
  ttD2CompanyPl: Scalars['NonNegativeFloat'];
  ttD2Payout: Scalars['NonNegativeFloat'];
  ttD2PlaceoutPl: Scalars['NonNegativeFloat'];
  ttD2TicketRebate: Scalars['NonNegativeFloat'];
  ttD2UplinePl: Scalars['NonNegativeFloat'];
  ttD3CompanyPl: Scalars['NonNegativeFloat'];
  ttD3Payout: Scalars['NonNegativeFloat'];
  ttD3PlaceoutPl: Scalars['NonNegativeFloat'];
  ttD3TicketRebate: Scalars['NonNegativeFloat'];
  ttD3UplinePl: Scalars['NonNegativeFloat'];
  ttD4CompanyPl: Scalars['NonNegativeFloat'];
  ttD4Payout: Scalars['NonNegativeFloat'];
  ttD4PlaceoutPl: Scalars['NonNegativeFloat'];
  ttD4TicketRebate: Scalars['NonNegativeFloat'];
  ttD4UplinePl: Scalars['NonNegativeFloat'];
  ttDownlineIntake: Scalars['Boolean'];
  ttEnabled: Scalars['Boolean'];
  ttIntake: Scalars['Boolean'];
  ttIntakeD1: Scalars['NonNegativeFloat'];
  ttIntakeD2: Scalars['NonNegativeFloat'];
  ttIntakeD3: Scalars['NonNegativeFloat'];
  ttIntakeD4: Scalars['NonNegativeFloat'];
  ttIntakeTax: Scalars['NonNegativeFloat'];
  ttManagementExpenses?: InputMaybe<Scalars['NonNegativeFloat']>;
};

export enum TtMode {
  R = 'R',
  R2 = 'R2',
  R3 = 'R3',
  R4 = 'R4',
  R5 = 'R5',
  R7 = 'R7',
  Te = 'TE',
  To = 'TO',
  Tt = 'TT',
  FourWild = 'fourWild',
  ThreeWild = 'threeWild',
  TwoWild = 'twoWild',
  Wild2 = 'wild2',
  Wild3 = 'wild3',
  Wild4 = 'wild4'
}

export type TtOutset = {
  confirmedAt?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  entries: Array<Scalars['JSON']>;
  externalCompanyId: Scalars['String'];
  fileName: Scalars['String'];
  id: Scalars['String'];
  page: Scalars['String'];
  readyAt?: Maybe<Scalars['Date']>;
};

export type TtPage = {
  calculation: TtEntryCalculation;
  createdAt: Scalars['Date'];
  enteredById: Scalars['String'];
  enteredFrom: EntrySource;
  entries: Array<Scalars['JSON']>;
  id: Scalars['String'];
  name: Scalars['String'];
  releaseDate: Scalars['Date'];
  /** return all releases relevant for page */
  releases: Array<PageRelease>;
  summary: TtEntryCalculation;
  userId: Scalars['String'];
  username?: Maybe<Scalars['String']>;
};


export type TtPageEntriesArgs = {
  releaseDates?: InputMaybe<Array<Scalars['Date']>>;
};


export type TtPageSummaryArgs = {
  releaseDates?: InputMaybe<Array<Scalars['Date']>>;
};

export type TtPagePayload = {
  entries: Array<TtEntryPayload>;
  entrySource: EntrySource;
  page: Scalars['String'];
  userId: Scalars['NonEmptyString'];
};

export type TtResult = {
  additionalNumber?: Maybe<Scalars['String']>;
  winningNumber1?: Maybe<Scalars['String']>;
  winningNumber2?: Maybe<Scalars['String']>;
  winningNumber3?: Maybe<Scalars['String']>;
  winningNumber4?: Maybe<Scalars['String']>;
  winningNumber5?: Maybe<Scalars['String']>;
  winningNumber6?: Maybe<Scalars['String']>;
};

export type TtBetSummary = {
  unit: Scalars['Float'];
};

export type TtCompanyReport = {
  /** TicketIn + Intake */
  companyTickets: TtCompanyStatement;
  /** companyTicket + outsetTickets */
  companyTicketsFinal: TtCompanyStatement;
  outsetTickets: TtCompanyStatement;
};

export type TtCompanyReportRow = {
  amount: Scalars['Float'];
  amountLessRebate: Scalars['Float'];
  externalCompanyTicketRebate?: Maybe<Scalars['Float']>;
  externalShareClaim?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  net: Scalars['Float'];
  position: Scalars['Float'];
  rebate: Scalars['Float'];
  strikeAmount: Scalars['Float'];
};

export type TtCompanyStatement = {
  rows: Array<TtCompanyReportRow>;
  summary: TtCompanyReportRow;
};

export type TtIntake = {
  id: Scalars['String'];
  numbersText: Scalars['String'];
  unit: Scalars['Float'];
  userId: Scalars['String'];
};

export type TtIntakeSettingPayload = {
  ttIntakeD1: Scalars['NonNegativeFloat'];
  ttIntakeD2: Scalars['NonNegativeFloat'];
  ttIntakeD3: Scalars['NonNegativeFloat'];
  ttIntakeD4: Scalars['NonNegativeFloat'];
};

export type TtNumberStake = {
  in: Scalars['Float'];
  intake: Scalars['Float'];
  number: Scalars['String'];
  toCompany: Scalars['Float'];
  userId: Scalars['String'];
};

export type TtOutsetBinBreak = {
  numbersText: Scalars['String'];
  payout: Scalars['Float'];
};

export type TtOutsetClaim = {
  id: Scalars['String'];
  numCount: Scalars['Int'];
  outsetBinBreaks: Array<TtOutsetBinBreak>;
  position: Scalars['Float'];
  shareClaim: Scalars['Float'];
};

export type TtOutsetClaimPayload = {
  externalShareClaim: Scalars['Float'];
  id: Scalars['UUID'];
  payouts: Array<TtPayoutPayload>;
  position: Scalars['Float'];
};

export type TtOutsetEntryPayload = {
  numbersText: Scalars['String'];
  unit: Scalars['NonNegativeInt'];
};

export type TtOutsetPayload = {
  entries: Array<TtOutsetEntryPayload>;
  externalCompanyIds: Array<Scalars['UUID']>;
};

export type TtOutsetStrikeNumberReport = {
  releaseDate: Scalars['Date'];
  rows: Array<TtOutsetStrikeNumberReportRow>;
};

export type TtOutsetStrikeNumberReportRow = {
  numbersText: Scalars['String'];
  page: Scalars['String'];
  strikeAmount: Scalars['Float'];
  time: Scalars['Date'];
  unit: Scalars['Float'];
  userId: Scalars['String'];
};

export type TtOutsetSummary = {
  unit: Scalars['Float'];
};

export type TtPayoutPayload = {
  numbersText: Scalars['String'];
  payout: Scalars['Float'];
};

export type TtResultsPayload = {
  additionalNumber?: InputMaybe<Scalars['String']>;
  winningNumber1?: InputMaybe<Scalars['String']>;
  winningNumber2?: InputMaybe<Scalars['String']>;
  winningNumber3?: InputMaybe<Scalars['String']>;
  winningNumber4?: InputMaybe<Scalars['String']>;
  winningNumber5?: InputMaybe<Scalars['String']>;
  winningNumber6?: InputMaybe<Scalars['String']>;
};

export type TtStakeFiles = {
  companyFinalStake: Array<Maybe<Scalars['JSON']>>;
  downlineEatStake: Array<Maybe<Scalars['JSON']>>;
  forOutsetStake: Array<Maybe<Scalars['JSON']>>;
  masterStake: Array<Maybe<Scalars['JSON']>>;
  outsetStake: Array<Maybe<Scalars['JSON']>>;
};

export type TtStrikeNumberReport = {
  releaseDate: Scalars['Date'];
  rows: Array<TtStrikeNumberReportRow>;
};

export type TtStrikeNumberReportRow = {
  entrySource: EntrySource;
  numbers: Array<Scalars['String']>;
  page: Scalars['String'];
  strikeAmount: Scalars['Float'];
  time: Scalars['Date'];
  unit: Scalars['Float'];
  userId: Scalars['String'];
};

export type TtStrikeSummaryReport = {
  releaseDate: Scalars['Date'];
  rows: Array<TtStrikeSummaryReportRow>;
};

export type TtStrikeSummaryReportRow = {
  companyStrikeAmount: Scalars['Float'];
  companyUnit: Scalars['Float'];
  numbers: Array<Scalars['String']>;
  outsetAmount: Scalars['Float'];
};

export type TtUserReport = {
  consolidated: TtUserReportRow;
  downlines: Array<TtUserReportRow>;
  intakeTickets: TtUserReportRow;
  personalTickets: TtUserReportRow;
};

export type TtUserReportRow = {
  amount: Scalars['Float'];
  amountLessRebate: Scalars['Float'];
  companyStake: Scalars['Float'];
  fixedExpenses: Scalars['Float'];
  net: Scalars['Float'];
  netBal: Scalars['Float'];
  position: Scalars['Float'];
  positionStatements?: Maybe<Array<PositionStatement>>;
  rebate: Scalars['Float'];
  strikeAmount: Scalars['Float'];
  userCompanyPl?: Maybe<Scalars['Float']>;
  userId: Scalars['String'];
  userTicketRebate?: Maybe<Scalars['Float']>;
  username?: Maybe<Scalars['String']>;
  variableExpenses?: Maybe<Scalars['Float']>;
};

export type UpdateExternalCompanyPayload = {
  bigLimit?: InputMaybe<Scalars['NonNegativeInt']>;
  commission?: InputMaybe<Scalars['NonNegativeInt']>;
  creditLimit?: InputMaybe<Scalars['NonNegativeInt']>;
  id: Scalars['UUID'];
  loginId?: InputMaybe<Scalars['String']>;
  loginPassword?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  smallLimit?: InputMaybe<Scalars['NonNegativeInt']>;
  timeClose?: InputMaybe<Scalars['String']>;
  unitLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

export type UpdateUserCreditPayload = {
  behalfOf: Scalars['NonEmptyString'];
  creditGiven: Scalars['NonNegativeFloat'];
  userId: Scalars['NonEmptyString'];
};

export type UpdateUserPayload = {
  behalfOf: Scalars['NonEmptyString'];
  creditGiven: Scalars['Float'];
  remarks: Scalars['String'];
  status: StatusEnum;
  userId: Scalars['NonEmptyString'];
  userPermissions: UserPermission;
  username: Scalars['NonEmptyString'];
};

export type UploadMeta = {
  fileName: Scalars['String'];
  fileSize: Scalars['Int'];
};

export type User = {
  autoResetBalance: Scalars['Boolean'];
  betSummary: BetSummary;
  createAgent: Scalars['Boolean'];
  createPayment: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  creditGiven: Scalars['Float'];
  creditSummary: CreditSummary;
  ddCanIntake: Scalars['Boolean'];
  ddCompanyPl: Scalars['Float'];
  ddConsolationPrizeCommission: Scalars['Float'];
  ddDownlineIntake: Scalars['Boolean'];
  ddEnabled: Scalars['Boolean'];
  ddFirstPrizeCommission: Scalars['Float'];
  ddIntake: Scalars['Boolean'];
  ddIntakeBig: Scalars['Float'];
  ddIntakeMethod: IntakeMethod;
  ddIntakeSmall: Scalars['Float'];
  ddIntakeTax: Scalars['Float'];
  ddManagementExpenses: Scalars['Float'];
  ddPlaceoutPl: Scalars['Float'];
  ddSecondPrizeCommission: Scalars['Float'];
  ddShareCompanyExpenses: Scalars['Boolean'];
  ddStarterPrizeCommission: Scalars['Float'];
  ddThirdPrizeCommission: Scalars['Float'];
  ddTicketRebate: Scalars['Float'];
  ddUplineIntakePl: Scalars['Float'];
  deleteBet: Scalars['Boolean'];
  deletedPages: Array<DeletedDdPage>;
  deletedTtPages: Array<DeletedTtPage>;
  downlines: Array<User>;
  downlinesCount: Scalars['Int'];
  intakeType?: Maybe<IntakeType>;
  intakes: Array<DownlineIntake>;
  isSuperUser?: Maybe<Scalars['Boolean']>;
  languageLocale: LanguageLocale;
  lineageIds: Array<Scalars['String']>;
  managerId?: Maybe<Scalars['String']>;
  mobile1: Scalars['String'];
  mobile2: Scalars['String'];
  mobile3: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  pages: Array<DdPage>;
  paymentHistory: Array<Payment>;
  paymentPayable: Array<Payment>;
  paymentPayableSum: Scalars['Float'];
  placeBets: Scalars['Boolean'];
  privacyMode: Scalars['Boolean'];
  readStatement: Scalars['Boolean'];
  remarks: Scalars['String'];
  roleName: RoleName;
  status: StatusEnum;
  storedPages: Array<StoredDdPage>;
  storedTtPages: Array<StoredTtPage>;
  summaryReport: Array<SummaryReportRow>;
  ttBetSummary: TtBetSummary;
  ttCanIntake: Scalars['Boolean'];
  ttD1CompanyPl: Scalars['Float'];
  ttD1Payout: Scalars['Float'];
  ttD1PlaceoutPl: Scalars['Float'];
  ttD1TicketRebate: Scalars['Float'];
  ttD1UplinePl: Scalars['Float'];
  ttD2CompanyPl: Scalars['Float'];
  ttD2Payout: Scalars['Float'];
  ttD2PlaceoutPl: Scalars['Float'];
  ttD2TicketRebate: Scalars['Float'];
  ttD2UplinePl: Scalars['Float'];
  ttD3CompanyPl: Scalars['Float'];
  ttD3Payout: Scalars['Float'];
  ttD3PlaceoutPl: Scalars['Float'];
  ttD3TicketRebate: Scalars['Float'];
  ttD3UplinePl: Scalars['Float'];
  ttD4CompanyPl: Scalars['Float'];
  ttD4Payout: Scalars['Float'];
  ttD4PlaceoutPl: Scalars['Float'];
  ttD4TicketRebate: Scalars['Float'];
  ttD4UplinePl: Scalars['Float'];
  ttDownlineIntake: Scalars['Boolean'];
  ttEnabled: Scalars['Boolean'];
  ttIntake: Scalars['Boolean'];
  ttIntakeD1: Scalars['Float'];
  ttIntakeD2: Scalars['Float'];
  ttIntakeD3: Scalars['Float'];
  ttIntakeD4: Scalars['Float'];
  ttIntakeTax: Scalars['Float'];
  ttIntakes: Array<DownlineIntake>;
  ttManagementExpenses: Scalars['Float'];
  ttPages: Array<TtPage>;
  ttShareCompanyExpenses: Scalars['Boolean'];
  uplineId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  username: Scalars['String'];
};


export type UserBetSummaryArgs = {
  includeDownlines: Scalars['Boolean'];
  releaseDate: Scalars['Date'];
};


export type UserDeletedPagesArgs = {
  releaseDate: Scalars['Date'];
};


export type UserDeletedTtPagesArgs = {
  releaseDate: Scalars['Date'];
};


export type UserDownlinesArgs = {
  includeAll?: InputMaybe<Scalars['Boolean']>;
};


export type UserIntakesArgs = {
  includeDownlines: Scalars['Boolean'];
  releaseDate: Scalars['Date'];
};


export type UserPagesArgs = {
  includeDownlines: Scalars['Boolean'];
  releaseDateEnd: Scalars['Date'];
  releaseDateStart: Scalars['Date'];
  reportMode: Scalars['Boolean'];
};


export type UserPaymentHistoryArgs = {
  withUser?: InputMaybe<Scalars['String']>;
};


export type UserStoredPagesArgs = {
  includeDownlines?: InputMaybe<Scalars['Boolean']>;
  storedPageType: StoredPageType;
};


export type UserStoredTtPagesArgs = {
  includeDownlines?: InputMaybe<Scalars['Boolean']>;
  storedPageType: StoredPageType;
};


export type UserTtBetSummaryArgs = {
  includeDownlines: Scalars['Boolean'];
  releaseDate: Scalars['Date'];
};


export type UserTtIntakesArgs = {
  includeDownlines: Scalars['Boolean'];
  releaseDate: Scalars['Date'];
};


export type UserTtPagesArgs = {
  includeDownlines: Scalars['Boolean'];
  releaseDateEnd: Scalars['Date'];
  releaseDateStart: Scalars['Date'];
  reportMode: Scalars['Boolean'];
};

export type UserPermission = {
  autoResetBalance: Scalars['Boolean'];
  createAgent: Scalars['Boolean'];
  createPayment: Scalars['Boolean'];
  deleteBet: Scalars['Boolean'];
  placeBets: Scalars['Boolean'];
  readStatement: Scalars['Boolean'];
};

export type UserReport = {
  consolidated: UserReportRow;
  downlines: Array<UserReportRow>;
  intakeTickets: UserReportRow;
  personalTickets: UserReportRow;
};

export type UserReportRow = {
  amount: Scalars['Float'];
  amountLessRebate: Scalars['Float'];
  big: Scalars['Float'];
  companyStake: Scalars['Float'];
  fixedExpenses: Scalars['Float'];
  net: Scalars['Float'];
  netBal: Scalars['Float'];
  position: Scalars['Float'];
  positionStatements?: Maybe<Array<PositionStatement>>;
  rebate: Scalars['Float'];
  small: Scalars['Float'];
  strikeAmount: Scalars['Float'];
  strikeCommission: Scalars['Float'];
  userCompanyPl?: Maybe<Scalars['Float']>;
  userId: Scalars['String'];
  userTicketRebate?: Maybe<Scalars['Float']>;
  username?: Maybe<Scalars['String']>;
  variableExpenses?: Maybe<Scalars['Float']>;
};

export type UserStakes = {
  downlineEatStake: Array<Scalars['JSON']>;
  masterStake: Array<Scalars['JSON']>;
};

export type WindowSettingPayload = {
  drawWindow: DrawWindow;
  fixedBet: FixedBet;
  profileChange: ProfileChange;
};

export type WindowSettings = {
  drawWindow: DrawWindowSetting;
  fixedBet: FixedBetWindowSetting;
  profileChange: ProfileChangeWindowSetting;
};
