
import { Vue, Component, Watch } from "vue-property-decorator";
import MassEntryTable2 from "@/components/modules/entry/MassEntryTable2.vue";
import EditRowsModal, {
  EditForm,
} from "@/components/modules/entry/EditRowsModal.vue";
import AccountSelectModal from "@/components/modules/account/AccountSelectModal.vue";
import { queries, mutations } from "@/apollo";
import {
  Release,
  AppMode,
  User,
  WindowSettings,
  StoredDdPage,
} from "@/CodegenTypes";
import { Day, EntryInput } from "@/types";
import { parseEntryInputs } from "@/helpers/dd/parseEntryInputs";
import { calculateEntryCost } from "@/helpers/dd/calculateEntryCost";
import { calculateEntries } from "@/helpers/dd/calculateEntries";
import { getValidDayOptions } from "@/helpers/getValidDayOptions";
import { clamp } from "lodash";
import { validateNumber } from "@/helpers/dd/validateNumber";
import { formatDollar } from "@/helpers/formatDollar";
import { errorMutationNotification } from "@/utils";
import { formatTimeSetting } from "@/helpers/formatTimeSetting";
import { customMoment } from "@/main";
import { useRootStore } from "@/store/pinia";
import { useTranslation } from "@/store/translation";

@Component({
  components: {
    MassEntryTable2,
    EditRowsModal,
    AccountSelectModal,
  },
  apollo: {
    releases: {
      query: queries.releases,
      variables() {
        return {
          appMode: useRootStore().appMode,
        };
      },
    },
    windowSettings: {
      query: queries.windowSettings,
      variables() {
        const appMode = useRootStore().appMode as AppMode;
        return {
          appMode: appMode,
        };
      },
    },

    user: {
      query: queries.userWithCreditSummary,
      skip() {
        return !this.selectedAccountId;
      },
      variables() {
        return {
          userId: this.selectedAccountId,
        };
      },
    },
    storedPages: {
      query: queries.userStoredPages,
      variables() {
        return {
          storedPageType: "fixed",
          includeDownlines: true,
          userId: this.selectedAccountId,
        };
      },
      update(data) {
        return data.user.storedPages;
      },
    },
  },
})
export default class FixedBet extends Vue {
  releases = null as null | Release[];
  selectedDayIndex = 0;
  selectedPageId = null as null | StoredDdPage["id"];
  page = "";

  t = useTranslation().$t;

  user = null as null | User;
  windowSettings = null as null | WindowSettings;

  storedPages = null as null | StoredDdPage[];

  showEditModal = false;

  isSubmitting = false;
  showAccountSelectionModal = false;

  editForm = {
    rowsFrom: undefined as undefined | number,
    rowsTo: undefined as undefined | number,
    big: undefined as undefined | EntryInput["big"],
    small: undefined as undefined | EntryInput["small"],
    mode: undefined as undefined | EntryInput["mode"],
  };

  formatDollar = formatDollar;
  formatTimeSetting = formatTimeSetting;

  MAX_ROWS = 60;

  tables = [{ startingIndex: 0 }, { startingIndex: 20 }, { startingIndex: 40 }];

  entries = [] as EntryInput[];

  get selectedAccountId() {
    return this.$route.query.userId;
  }

  get creditForDisplay() {
    return {
      creditGiven: this.user?.creditGiven || 0,
      creditUsed: this.user?.creditSummary.creditUsed || 0,
      creditBalance: this.user?.creditSummary.creditBalance || 0,
    };
  }

  get pageOptions() {
    const defaultOption = { label: "-- New --", value: null };

    if (!this.storedPages) {
      return [defaultOption];
    }
    const allOptions = this.storedPages.map((page) => {
      return {
        label: `[${page.userId.toUpperCase()}, ${page.username}] ${page.name}`,
        value: page.id,
      };
    });

    return [defaultOption, ...allOptions];
  }

  get selectedDay() {
    if (this.releases) {
      return this.dayOptions[this.selectedDayIndex].value as Day;
    } else {
      return 0;
    }
  }

  set selectedDay(val) {
    const index = this.dayOptions.findIndex((opt) => opt.value === val) as Day;

    this.selectedDayIndex = index;
  }

  get openReleases() {
    return this.releases?.filter((release) => release.windowIsOpen);
  }

  async mounted() {
    // while (!this.releases) {
    //   await new Promise(resolve => {
    //     setTimeout(() => {
    //       resolve();
    //     }, 10);
    //   });
    // }

    const result = await this.$apollo.query<{ releases: Release[] }>({
      query: queries.releases,
      variables: {
        appMode: useRootStore().appMode,
      },
    });
    this.releases = result.data.releases;
    const priorityIndex = this.dayOptions.findIndex(
      (opt) => opt.defaultPriority
    );
    Vue.set(this, "selectedDayIndex", priorityIndex === -1 ? 0 : priorityIndex);
    for (let i = 0; i < this.MAX_ROWS; i++) {
      this.entries.push({
        day: this.selectedDay,
        number: "",
        big: undefined,
        small: undefined,
        mode: undefined,
      });
    }
  }

  get validEntries() {
    return parseEntryInputs(this.entries);
  }

  get calculations() {
    return calculateEntries(this.validEntries);
  }

  get validDayOptions() {
    if (!this.releases) {
      return [];
    }
    const validOptions = getValidDayOptions(this.releases);
    return validOptions;
  }

  get dayOptions() {
    const OPTIONS = [
      // { label: "0=<Wed, Sat, Sun>", value: 0, defaultPriority: false },
      // { label: "2=<Sat, Sun>", value: 2, defaultPriority: false },
      // { label: "3=<Wed>", value: 3, defaultPriority: true },
      // { label: "6=<Sat>", value: 6, defaultPriority: false },
      { label: "7=<Sun>", value: 7, defaultPriority: false },
    ];

    return OPTIONS.filter((o) => this.validDayOptions.includes(o.value));
  }

  get totalCost() {
    const costs = this.validEntries.map((entry) => {
      return calculateEntryCost(entry);
    });
    const bigCosts = costs.reduce((sum, cost) => {
      sum += cost.big;
      return sum;
    }, 0);
    const smallCosts = costs.reduce((sum, cost) => {
      sum += cost.small;
      return sum;
    }, 0);

    return {
      big: bigCosts,
      small: smallCosts,
    };
  }

  get selectedPage() {
    if (!this.storedPages) {
      return null;
    }
    return this.storedPages.find((p) => p.id === this.selectedPageId);
  }

  @Watch("selectedPage")
  fillWithPage() {
    const page = this.selectedPage;
    this.doClearEntries();
    if (!page) {
      this.page = "";
      return;
    } else {
      this.page = page.name;
    }
    const newEntries = [...this.entries] as EntryInput[];
    for (const [index, entry] of page.entries.entries()) {
      newEntries[index].number = entry.number;
      newEntries[index].big = String(entry.big);
      newEntries[index].small = String(entry.small);
      newEntries[index].mode = entry.mode || undefined;
    }
    this.entries = newEntries;
  }

  @Watch("selectedAccountId")
  resetPage() {
    this.page = "";
    this.selectedPageId = null;
  }

  toggleEditModal() {
    this.showEditModal = !this.showEditModal;
  }

  changeSelectedDay(newVal: Day) {
    for (const entry of this.entries) {
      entry.day = newVal;
    }
  }

  focus(field: "number" | "big" | "small", inputTargetIndex: number) {
    let targetIndex = inputTargetIndex;
    if (targetIndex < 0) {
      targetIndex = 59;
    } else if (targetIndex > 59) {
      targetIndex = 0;
    }
    const tablesEles = this.$refs["mass-entry-table"] as any;
    for (const [i, table] of this.tables.entries()) {
      const startingIndex = table.startingIndex;
      if (targetIndex >= startingIndex && targetIndex < startingIndex + 20) {
        tablesEles[i].focus(field, targetIndex);
      }
    }
    return;
  }

  performEditRows(editForm: EditForm) {
    if (!editForm.rowsFrom || !editForm.rowsTo) {
      return;
    }

    // make sure in range
    const rowsFrom = clamp(editForm.rowsFrom, 0, this.MAX_ROWS);
    const rowsTo = clamp(editForm.rowsTo, 0, this.MAX_ROWS);

    // make sure rowsFrom is smaller than rowsTo
    const range = [rowsFrom, rowsTo].sort((a, b) => a - b);

    for (let i = range[0] - 1; i <= range[1] - 1; i++) {
      this.entries[i].big = editForm.big;
      this.entries[i].small = editForm.small;
      this.entries[i].mode = editForm.mode;
    }
    this.editForm = {
      rowsFrom: undefined,
      rowsTo: undefined,
      big: undefined,
      small: undefined,
      mode: undefined,
    };
    this.showEditModal = false;
  }

  async clearEntries(event: Event) {
    this.$confirm.require({
      target: event.currentTarget,
      group: 'popups',
      header: "Clear the form?",
      message: "All existing inputs will be deleted.",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        // callback to execute when user confirms the action
        this.doClearEntries();
      },
      reject: () => {
        // callback to execute when user rejects the action
      },
    });
  }

  doClearEntries() {
    const newEntries = [];

    for (let i = 0; i < this.MAX_ROWS; i++) {
      newEntries.push({
        day: this.selectedDay,
        number: "",
        big: undefined,
        small: undefined,
        mode: undefined,
      });
    }
    this.entries = newEntries;
  }

  validateAllAndShowError() {
    const errors = [];
    for (const [index, entry] of this.entries.entries()) {
      const validNumber = validateNumber(entry);
      const noSmall = (entry.small as any) === "" || entry.small === undefined;
      const noBig = (entry.big as any) === "" || entry.big === undefined;
      const hasMode = entry.mode;
      if (entry.number === "" && noSmall && noBig && !hasMode) {
        continue;
      }
      if (!validNumber) {
        errors.push(`Row ${index + 1} has invalid number`);
      }
      if (validNumber && noSmall && noBig) {
        errors.push(`Row ${index + 1} has no big and small`);
      }
    }
    return errors;
  }

  async handleDelete() {
  if (!this.selectedAccountId) {
    this.$toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'No account selected',
      life: 3000
    });
    return;
  }

  this.$confirm.require({
    header: 'Confirm?',
    message: this.t("Are you sure to delete the fixed bet?"),
    icon: 'pi pi-exclamation-triangle',
    accept: async () => {
      try {
        this.isSubmitting = true;
        await this.$apollo.mutate({
          mutation: mutations.deleteSavedDdPage,
          variables: {
            id: this.selectedPage?.id,
          },
        });
        this.$apollo.queries.storedPages.refetch();
        
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Fixed bet deleted.',
          life: 3000
        });
      } catch (err) {
        errorMutationNotification(err);
      } finally {
        this.isSubmitting = false;
      }
    },
    reject: () => {
      // Optional: Handle rejection
    }
  });
}

async handleSave() {
  const errors = this.validateAllAndShowError();
  if (errors.length) {
    this.$toast.add({
      severity: 'error',
      summary: 'Error',
      detail: errors[0],
      life: 3000
    });
    return;
  }

  if (!this.selectedAccountId) {
    this.$toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'No account selected',
      life: 3000
    });
    return;
  }

  const entries = this.validEntries;

  if (!entries.length) {
    this.$toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Please enter number details for entries',
      life: 3000
    });
    return;
  }

  this.$confirm.require({
    header: 'Confirm?',
    message: this.t("Are you sure to save the entries for Fixed betting?"),
    icon: 'pi pi-exclamation-triangle',
    accept: async () => {
      try {
        this.isSubmitting = true;
        const { data } = await this.$apollo.mutate({
          mutation: mutations.saveDdEntry,
          variables: {
            page: {
              id: this.selectedPage?.id || undefined,
              page: this.page,
              entries,
              userId: this.selectedAccountId,
              storedPageType: "fixed",
            },
          },
        });
        this.selectedPageId = data.saveDdEntry;
        this.$apollo.queries.storedPages.refetch();
        
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Entry saved successfully.',
          life: 3000
        });
        
        this.fillWithPage();
      } catch (err) {
        errorMutationNotification(err);
      } finally {
        this.isSubmitting = false;
      }
    },
    reject: () => {
      // Optional: Handle rejection
    }
  });
}


  formatDate(dateStr: string) {
    return customMoment(dateStr).format("ddd, DD MMM YYYY");
  }
}
