
import { Vue, Component } from "vue-property-decorator";
import TTDeletedPageDetailsModal from "@/components/modules/bet-details/TTDeletedPageDetailsModal.vue";
import UserBreadcrumb from "@/components/common/UserBreadcrumb.vue";
import { queries } from "@/apollo";
import { User, DeletedTtPage, TtPage } from "@/CodegenTypes";

import { sumBy, startCase, first } from "lodash";
import { formatDollar } from "@/helpers/formatDollar";
import { formatCount } from "@/helpers/formatCount";
import AccountSelectModal from "@/components/modules/account/AccountSelectModal.vue";
import AccountSelectSearch from "@/components/modules/account/AccountSelectSearch.vue";
import { customMoment } from "@/main";
import { useTranslation } from "@/store/translation";

@Component({
  apollo: {
    user: {
      query: queries.userDeletedTtPages,
      skip() {
        return !this.selectedStartReleaseDate;
      },
      variables() {
        return {
          releaseDate: customMoment(this.selectedStartReleaseDate)
            .startOf("day")
            .toDate(),
          behalfOf: this.$route.query.userId,
        };
      },
    },
  },
  components: {
    UserBreadcrumb,
    TTDeletedPageDetailsModal,
    AccountSelectModal,
    AccountSelectSearch,
  },
})
export default class TTDeletedBetDetails extends Vue {
  user = null as null | User;

  showAccountSelectionModal = false;

  startCase = startCase;

  formatDollar = formatDollar;
  formatCount = formatCount;

  t = useTranslation().$t;  selectedPage = null as null | DeletedTtPage;
  showPageDetailModal = false;

  get selectedStartReleaseDate() {
    const query = this.$route.query.startReleaseDate;
    return Array.isArray(query) ? first(query) : query;
  }

  get pageDetailTotal() {
    const data = this.pageDetailsData;
    return {
      unit: sumBy(data, (d) => d.unit).toFixed(2),
    };
  }

  get pageDetailsData() {
    const currUser = this.user;
    if (!currUser) {
      return [];
    }

    const downlinesData = currUser.deletedTtPages
      .map((page) => {
        const unit = page.calculation.unit;

        return {
          ...page,
          upline: page.userId,
          uplineName: page.username,
          account: page.userId,
          username: page.username,
          unit,
          createdAt: page.createdAt,
        };
      })
      .filter((u) => u.unit > 0)
      .sort(
        (u1, u2) =>
          customMoment(u1.createdAt).unix() - customMoment(u2.createdAt).unix()
      )
      .sort((u1, u2) => u1.upline.localeCompare(u2.upline));

    const rowData = downlinesData.map((row) => {
      return {
        ...row,
        uplineCount: downlinesData.filter((d) => d.upline === row.upline)
          .length,
        accountCount: downlinesData.filter((d) => d.account === row.account)
          .length,
      };
    });
    return rowData;
  }

  formatDate(dateStr: string) {
    return customMoment(dateStr).format("ddd, DD/MM/YYYY hh:mm:ss A");
  }

  reloadAll() {
    Object.values(this.$apollo.queries).forEach((q) => q.refresh());
  }

  viewPageDetails(id: TtPage["id"]) {
    const page = this.pageDetailsData.find((p) => p.id === id);
    if (!page) {
      return;
    }
    this.selectedPage = page;
    this.showPageDetailModal = true;
  }
}
