
import { User } from "@/CodegenTypes";
import { defineStore } from "pinia";
import { ref } from "vue";

export const SUPPORTED_LANGUAGES = {
  en: "en",
  zh: "zh",
} as const;

export type LanguageLocale = keyof typeof SUPPORTED_LANGUAGES;

const translations = new Map<string, string>(
  Object.entries({
    login: "登录",
    Login: "登录",
    Main: "主页",
    "View Pages": "查看页面",
    "Pages": "页面",
    "Bet by": "输入法", 
    "Entered by": "输入人",
    "Game": "游戏",
    "Company In": "公司进",
    "PL": "盈亏",
    "Agent Ticket": "代理单",
    "Given %": "佣金%",
    "Expand All": "展开全部",
    "Collapse All": "收起全部",
    "Select Account": "选择账户",
    "Game Setting": "游戏设置",
    "Simple Bet": "简单下注",
    "Edit profile": "编辑资料",
    "Co Tkt": "公司票额",
    "Commission": "佣金",
    "Grp": "组",
    "Management Expenses": "管理费",
    "Total Placeout": "总出单",
    "Upline Commission": "上线佣金",
    "Win/Loss": "输赢",
    "Exp": "费用",
    "Co PL": "公司盈亏",
    "PL Nett": "盈亏净额",
    "Nett": "净额",
    Profile: "户口详情",
    Example: "示例",
    Delete: "删除",
    Edit: "编辑",
    "Copy old bets": "复制投注",
    "View bets": "查看投注",
    "View details": "查看详情",
    "Edit page": "编辑页面",
    "Save bet": "保存注单",
    "Delete page": "删除页面",
    Credit: "限额",
    "Current Password": "当前密码",
    "Create Downline": "创建下线",
    "Update Intake": "更新吃单",
    Enabled: "开用",
    Odds: "赔率",
    "New Password": "新密码",
    "Confirm New Password": "确认新密码",
    "Update Nickname": "更新昵称",
    "Credit Used": "已用限额",
    "Credit Balance": "限额结余",
    "Credit Left": "限额结余",
    Settings: "设置",
    comm: "率",
    Announcements: "公告",
    "My Profile": "户口详情",
    "Update Mobile": "更新手机号",
    "Change password": "修改密码",
    "User Id": "登入号",
    "User Name": "名称",
    Nickname: "别名",
    Password: "密码",
    "Role name": "段位名称",
    "Credit Given": "支限金额",
    "Credit Available": "支限余额 ",
    "Intake Type": "吃单类",
    "Create Agent": "开户口",
    "Read Statement": "阅览报表",
    "Create Payment": "开结账表",
    Mobile: "手提",
    Agree: "同意",
    Disagree: "不同意",
    "Terms and Conditions": "条款和条件",
    Downlines: "下线",
    downlines: "下线",
    active: "活跃",
    locked: "锁定",
    "My Downlines": "下线",
    Role: "段位",
    Type: "类",
    Status: "现状",
    "Give Downlines": "下线支付限额",
    "Pending Bets": "下注中金额",
    "Update Game setting": "更新下注设置",
    "SG4D Enabled": "SG4D启用",
    "Ticket rebate": "单钱回扣",
    "Company PL Fight": "公司输赢分成",
    "PO PL Fight": "出单输赢分成",
    Intake: "吃单",
    "Intake Tkt Big": "吃单大",
    "Intake Tkt Small": "吃单小",
    Big: "大",
    Small: "小",
    Manager: "经理",
    "User ID": "登入号",
    "Intake Method": "吃单分类",
    "Downline intake": "下线吃单",
    "1st Prize Comm": "头奖回扣",
    "2nd Prize Comm": "二奖回扣",
    "3rd Prize Comm": "三奖回扣",
    "Starter Prize Comm": "入围回扣",
    "Conso Prize Comm": "安慰奖回扣",
    "Intake Details": "吃单详情",
    Cancel: "取消",
    "Update 4D": "更新4D设置",
    "TOTO Enabled": "TOTO 启用",
    "Downline Intake": "下线吃单",
    "Num Type": "号码下注类",
    "Ticket Rebate": "单钱回扣",
    Payout: "赔率",
    "Placeout PL Fight": "出单输赢分成",
    "Intake Ticket $": "吃单限额",
    "Update ToTo": "更新ToTo设置",
    "Edit Profile": "更改户口",
    "Intake type": "吃单类",
    Details: "细节",
    "Given Credit ": "支限金额",
    Active: "活跃",
    Lock: "沉睡",
    Remarks: "多余注解",
    Permissions: "权限",
    "Delete Bet": "删除下注",
    Statement: "报表",
    "Auto Reset Balance": "系统自动重置余额",
    "Place Bets": "下注",
    Payment: "款项",
    Collection: "收款",
    "Credit History": "限额记录",
    "credit Left": "支限余额",
    Auto: "自动",
    Outstanding: "余款",
    Description: "注解",
    Amount: "额",
    Report: "报表",
    "Report Mode": "报表模式",
    Update: "更新",
    "Bet Entry": "下注",
    "Bet Entry Dark": "下注(暗)",
    "Simple Entry": "便利下注",
    "Set All To": "设置所有下注",
    BIP: "下注中",
    Available: "可用的",
    Clear: "清空",
    edit: "更改",
    Submit: "输入",
    unit: "单位",
    type: "类",
    "Advance Bet": "多功能下注",
    "Advance Bet Dark": "多功能下注(暗)",
    "Fixed Bet": "定期下注",
    Note: "注意",
    "Fixed Numbers are closed at": "定期下注存入将关闭于",
    "and are submitted to System at": "已存入定期将入系统于",
    delete: "删除",
    Save: "存入",
    "Saved Bet": "存入下注",
    Upline: "上线",
    "File Upload": "文件上转",
    "Upload File": "上转文件",
    Account: "户口",
    "Select Day": "则日期",
    browse: "浏览",
    "Upload ": "上转",
    "Drag and drop files to here to upload.": "将文件拖放到此处进行上传。",
    "Your Uploaded Files": "您已上转文件",
    "File name": "文件名",
    "File size": "文件大小",
    Page: "页面",
    "Uploaded Time": "上转时间",
    Day: "日",
    "Upload by": "上转者",
    Count: "号码数",
    Unit: "单位",
    Action: "动作",
    "Telegram Help": "Telegram帮助",
    "Notes: These data files are still pending, not submitted yet.":
      "注意：以上显示已上转号码还为输入系统结算。",
    "Bet List": "下注表",
    "Bet Details": "下注详情",
    "Deleted Bet Details": "已删除详情",
    "User Stake": "用户赌注",
    "Downline summary": "下线总结",
    "User summary": "用户总结",
    Total: "总额",
    "Page details": "页面详情",
    Agent: "代理人",
    Downline: "下线",
    "Bet By": "下注者",
    "Entered By": "输入者",
    Time: "时间",
    "Net Total": "净总值",
    "view details": "查看详情",
    "Edit Page": "更改页面",
    "Delete Page": "删除页面",
    "Entry date": "输入日期",
    "Total Unit": "总单位",
    Entries: "输入",
    "Delete ": "删除 ",
    Num: "号码",
    D: "日",
    T: "打",
    Del: "删 ",
    "Update Page": "页面更改",
    "Page Name": "页面名",
    "Draw dates to delete": "开彩日期要删除",
    "Deleted Bet Summary": "已删除的投注摘要",
    Payable: "应付",
    "deleted by": "删除者",
    "Deleted Time": "删除时间",
    "Stake File": "赌注档案",
    "Bet in process": "下注中",
    "Download [Company Final] PDF": "下载 [公司总结] PDF",
    Stake: "赌注",
    "Number Count": "号码数",
    "Company Final": "公司总结",
    "Click to change selected stake": "点 击以更改选定的股份",
    Number: "号码",
    report: "报表",
    "Company Profit/Loss Report": "公司盈余报表",
    "Company Profit/Loss": "公司盈余报表",
    Company: "公司",
    Ticket: "单",
    Rebate: "回扣",
    "Less Rebate": "减回扣",
    Strike: "中奖",
    Net: "净值",
    Expenses: "费用",
    "Company Total": "公司总数",
    "Company Profit / Loss": "公司盈亏",
    "Company Stake Report": "公司分成表",
    "Tickets In": "来单",
    Position: "分成",
    "Ticket Bal": "余额",
    "Tickets Out": "出单",
    WinLoss: "输赢",
    "Company Full Report": "完整报表",
    "Co Stake": "公司分成",
    "Net ": "净额",
    Out: "出",
    Comm: "水",
    "Share Claim": "股份认领",
    "Strike Report": "中奖报表",
    Summary: "简报",
    Prize: "奖",
    "Company Strike": "公司中奖",
    "Out Strike": "出单中奖",
    "By Acount": "按帐户",
    "Draw Date": "开奖日期",
    "Strike ": "中奖",
    "Entry From": "输入",
    account: "户口",
    page: "页面",
    "Mass Entry V2": "多功能下注",
    "Mass Entry": "多功能下注",
    "View Log": "查看日志",
    "Summary Report": "总结报表",
    "Login History": "登录历史",
    "Audit Log": "审计日志",
    "Intake Tax": "吃单税",
    Placeout: "出单",
    "Winning Number": "中奖号码",
    "Deleted By": "删除者",
    "Intake Report": "吃单报表",
    Download: "下载",
    "Company Out": "公司出单",
    "Master Bets": "主下注",
    "Find number": "查找号码",
    "Max Payout": "最大支付",
    "First Prize": "头奖",
    Export: "下载",
    iBig: "i大",
    iSmall: "i小",
    "Betting closed. No open draws currently.": "下注已关闭，目前没有开彩。",
    In: "进",
    "All audit log details recorded in UTC timezone. For SG timezone, +8 hours.":
      "所有审计日志详情记录在UTC时区。对于SG时区，+8小时。",
    "Draw is open until": "下注直到",
    "Draw Results": "开奖结果",
    Date: "日期",
    Scomm: "中奖水",
    SComm: "中奖水",
    "By Account": "按帐户",
    "By Number": "按号码",
    Tickets: "单",
    Subtotal: "小计",
    "Net Bal": "净余额",
    "Personal Tickets": "私人单",
    "Payment Balance and Consolidation": "付款余额和聚合",
    "Net Profit/Loss": "净盈亏",
    "Profit/Loss": "盈亏",
    "Winning Numbers": "开奖号码",
    "Additional Number": "额外号码",
    "Payout Guide": "玩法指南",
  })
);

export const useTranslation = defineStore("translation", () => {


  const languageLocale = ref<LanguageLocale>( SUPPORTED_LANGUAGES.en);
  const privacyMode = ref( false);



  function initUserPreference(user: User) {
    languageLocale.value = user.languageLocale;
    privacyMode.value = user.privacyMode;
  }

  function handleTranslation(text: string) {
    if (languageLocale.value === SUPPORTED_LANGUAGES.en) return text;

    if (languageLocale.value === SUPPORTED_LANGUAGES.zh) {
      return translations.get(text) ?? text;
    }
    return text;
  }

  function handlePrivacyMode(text: string) {
    if (privacyMode.value) {
      return text
        .replaceAll("betting", "ordering")
        .replaceAll("Betting", "Ordering")
        .replaceAll("bet", "order")
        .replaceAll(/big/gi, "B")
        .replaceAll(/small/gi, "S")
        .replaceAll("Bet", "Order")
        .replaceAll("下注", "下单")
        .replaceAll("4D", "DD")
        .replaceAll("TOTO", "TT");
    }

    return text;
  }

  
  function setLanguage(locale: LanguageLocale) {
    languageLocale.value = locale;
  }

  function togglePrivacyMode() {
    privacyMode.value = !privacyMode.value;
  }

  function $t(text: string) {
    const translated = handleTranslation(text);

    if (!privacyMode.value) {
      return translated;
    }
    return handlePrivacyMode(translated);
  }

  return {
    languageLocale,
    privacyMode,
    setLanguage,
    togglePrivacyMode,
    initUserPreference,
    $t,
  };
}, {
  persist: {
    paths: ["languageLocale", "privacyMode"],
  },
});
