import { defineStore } from 'pinia';

interface Dialog {
  text: string;
}

export const useDialogStore = defineStore('dialog', {
  state: () => ({
    dialogs: [] as Dialog[]
  }),
  actions: {
    addDialog({text}: {text: string}) {
      this.dialogs.push({ text });
    },
    removeDialog(index: number) {
      this.dialogs.splice(index, 1);
    }
  }
});
