var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Loader',{staticClass:"w-full max-w-[500px]",model:{value:(_vm.$apollo.loading),callback:function ($$v) {_vm.$set(_vm.$apollo, "loading", $$v)},expression:"$apollo.loading"}},[_c('section',{staticClass:"overflow-x-auto max-w-full"},[_c('h2',[_vm._v(_vm._s(_vm.t("Downline summary")))]),_c('table',{staticClass:"table-auto theme-table hoverable striped"},[_c('thead',[_c('th',[_vm._v(_vm._s(_vm.t("Account")))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.t("Amount")))])]),_c('tbody',{},_vm._l((_vm.downlineSummaryData),function(row){return _c('tr',{key:row.userId},[_c('td',[(_vm.user && row.userId !== _vm.user.userId)?_c('span',[_c('router-link',{staticClass:"uppercase text-primary-600",attrs:{"to":{
                name: _vm.$route.name,
                query: {
                  ..._vm.$route.query,
                  userId: row.userId,
                },
              }}},[_vm._v(_vm._s(row.userId))]),_c('span',{staticClass:"text-sm text-gray-500"},[_vm._v(" "+_vm._s(row.username)+" ")])],1):[_c('span',{staticClass:"uppercase font-medium"},[_vm._v(" "+_vm._s(row.userId)+" ")])]],2),_c('td',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(_vm.formatDollar(row.amount)))])])])}),0),_c('tfoot',[_c('tr',[_c('td',{staticClass:"text-right; pr-[40px]",attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.t("Total"))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.downlineSummaryTotal.amount)+" ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }