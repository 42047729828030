
import { Vue, Component, Prop } from "vue-property-decorator";
import { validateNumber } from "@/helpers/dd/validateNumber";
import { Day, Entry, EntryInput } from "@/types";
import { parseEntryInputs } from "@/helpers/dd/parseEntryInputs";
import { calculateEntries } from "@/helpers/dd/calculateEntries/index";
import { countPermutations } from "@/helpers/dd/countPermutations";
import { countNumberX } from "@/helpers/dd/countNumberX";
import { X_LIMIT } from "@/config";
import { generateRandom4d } from "@/helpers/dd/generateRandom4d";
import { useTranslation } from "@/store/translation";
import { useDialogStore } from "@/store/dialogs";

@Component
export default class MassEntryTable2 extends Vue {
  @Prop({ default: true }) showDay!: boolean;
  @Prop({ required: true }) selectedDay!: Day;
  @Prop({ default: 0 }) startingIndex!: number;
  @Prop({ required: true }) value!: EntryInput[];
  @Prop({ required: true }) dayOptions!: {
    label: string;
    value: number;
  }[];

  t = useTranslation().$t;

  modeOptions = [
    { label: "", value: undefined },
    { label: "R", value: "R" },
    { label: "i", value: "i" },
    { label: "k", value: "k" },
  ];

  get entries() {
    return this.value.slice(this.startingIndex, this.startingIndex + 20);
  }

  get validEntries() {
    return parseEntryInputs(this.entries);
  }

  get calculations() {
    return calculateEntries(this.validEntries);
  }

  handleKeyPresses(event: KeyboardEvent, entry: EntryInput) {
    this.handleModeInput(event, entry);
    this.handleDayInput(event, entry);
  }

  handleQuickBet(index: number) {
    const generated4d = generateRandom4d();

    this.entries[index].number = generated4d;
  }

  handleEnter(
    event: KeyboardEvent,
    field: "number" | "big" | "small",
    index: number
  ) {
    debugger;
    const prevEntry = this.value[Math.max(index + this.startingIndex - 1, 0)];
    const currEntry = this.entries[index];
    if (event.key === "Enter") {
      switch (field) {
        case "number":
          if (validateNumber(currEntry)) {
            this.focus("big", index + this.startingIndex);
          }
          break;
        case "big":
          if (currEntry.big === undefined || (currEntry.big as any) === "") {
            currEntry.big = prevEntry.big || "0";
          }
          this.focus("small", index + this.startingIndex);
          break;
        case "small":
          if (
            currEntry.small === undefined ||
            (currEntry.small as any) === ""
          ) {
            currEntry.small = prevEntry.small || "0";
          }
          this.focus("number", index + 1 + this.startingIndex);
          break;
      }
      event.preventDefault();
      return;
    }
  }

  handleUnfocus(
    event: KeyboardEvent,
    field: "number" | "big" | "small",
    index: number
  ) {
    const currEntry = this.entries[index];
    switch (field) {
      case "number":
        if (
          isNaN(Number(currEntry.number)) ||
          currEntry.number.trim().length < 4
        ) {
          currEntry.number = "";
        }
        break;
      case "big":
        if (currEntry.big && Number(currEntry.big) > 500) {
          useDialogStore().addDialog({
            text: "Big should be <= 500",
          });
          currEntry.big = "0";
        }
        if (currEntry.big && Number(currEntry.big) % 0.25 !== 0) {
          useDialogStore().addDialog({
            text: "Decimal can only be 0.25, 0.5 and 0.75",
          });
          currEntry.big = "0";
        }
        break;
      case "small":
        if (currEntry.small && Number(currEntry.small) > 500) {
          useDialogStore().addDialog({
            text: "Small should be <= 500",
          });
          currEntry.small = "0";
        }
        break;
    }
  }

  handlePlusMinusKey(
    event: KeyboardEvent,
    field: "number" | "big" | "small",
    index: number
  ) {
    const currEntry = this.entries[index];
    if (event.key === "-") {
      switch (field) {
        case "number":
          currEntry.number = "";
          break;
        case "big":
          currEntry.big = undefined;
          break;
        case "small":
          currEntry.small = undefined;
          break;
      }
      event.preventDefault();
      return;
    } else if (event.key === "+") {
      if (index + this.startingIndex === 0) {
        return;
      }
      const validation = validateNumber(currEntry);
      if (!validation) {
        return;
      }
      const prevEntry = this.value[index + this.startingIndex - 1];
      currEntry.big = prevEntry.big;
      currEntry.small = prevEntry.small;
      currEntry.mode = prevEntry.mode;
      this.focus("number", index + 1 + this.startingIndex);
      event.preventDefault();
    }
  }

  handleArrowKey(
    event: KeyboardEvent,
    field: "number" | "big" | "small",
    index: number
  ) {
    const key = event.key;

    let indexDiff = 0;
    let targetField = field;

    let triggered = false;

    switch (key) {
      case "ArrowLeft":
        triggered = true;
        if (field === "number") {
          targetField = "small";
          indexDiff--;
        } else if (field === "big") {
          targetField = "number";
        } else if (field === "small") {
          targetField = "big";
        }
        break;
      case "ArrowRight":
        triggered = true;
        if (field === "number") {
          targetField = "big";
        } else if (field === "big") {
          targetField = "small";
        } else if (field === "small") {
          targetField = "number";
          indexDiff++;
        }
        break;
      case "ArrowUp":
        triggered = true;
        indexDiff--;
        break;
      case "ArrowDown":
        triggered = true;
        indexDiff++;
        break;
    }
    const targetIndex = index + indexDiff;
    // targetIndex = Math.min(targetIndex, 59);
    // targetIndex = Math.max(targetIndex, 0);
    if (triggered) {
      event.preventDefault();
      this.focus(targetField, targetIndex + this.startingIndex);
    }
  }

  focus(field: "number" | "big" | "small", inputTargetindex: number) {
    // if index fall out of range, emit to focus on another table.
    if (
      inputTargetindex < this.startingIndex ||
      inputTargetindex >= this.startingIndex + 20
    ) {
      this.$emit("focus", field, inputTargetindex);
      return;
    }
    const targetIndex = inputTargetindex - this.startingIndex;
    const inputElements = this.$refs[field] as Vue[];
    if (!inputElements?.length) {
      return;
    }
    const inputElement = inputElements[targetIndex] as any;
    inputElement?.$el?.focus();
    inputElement?.$el?.select();
  }

  handleModeInput(event: KeyboardEvent, entry: EntryInput) {
    // don't allow roll if permutatations = 1.
    const modes = ["R", "i", "k", undefined] as Entry["mode"][];
    const perms = countPermutations(entry.number);

    const xCount = countNumberX(entry.number);

    // is X input
    if (event.key.match(/x/gi)) {
      if (xCount >= X_LIMIT) {
        event.preventDefault();
        return;
      }
      if (entry.mode) {
        event.preventDefault();
        return;
      }
    }

    if ([...modes, "/", "r"].includes(event.key) && xCount > 0) {
      event.preventDefault();
      return;
    }

    // prevent i & R roll on unrollable numbers
    if (["i", "/", "r", "R"].includes(event.key) && perms === 1) {
      Vue.set(entry, "mode", undefined);
      event.preventDefault();
      return;
    }

    // cancel mode
    if (event.key.toLowerCase() == entry.mode?.toLowerCase()) {
      Vue.set(entry, "mode", undefined);
      event.preventDefault();
    } else if (event.key === "i") {
      entry.mode = "i";
      event.preventDefault();
    } else if (event.key === "r") {
      entry.mode = "R";
      event.preventDefault();
    } else if (event.key === "k") {
      entry.mode = "k";
      event.preventDefault();
    } else if (event.key === "/") {
      const currIndex = modes.findIndex((mode) => {
        return mode?.toLowerCase() === entry.mode?.toLowerCase();
      });
      if (currIndex < 0) {
        throw new Error("unforeseen mode found: " + entry.mode);
      }
      let nextIndex = currIndex + 1;
      if (nextIndex === modes.length) {
        nextIndex = 0;
      }
      entry.mode = modes[nextIndex];
      event.preventDefault();
    }
  }

  handleXInput(event: KeyboardEvent, entry: EntryInput) {
    // if (event.key === "X" || event.key === "x") {
    //   if ((entry.mode && entry.mode === "R") || entry.mode === "i") {
    //     // when there is existing mode, cannot press X;
    //     event.preventDefault();
    //   }
    //   const currNum = entry.number;
    //   if ((currNum.match(/x/gi) || []).length >= 2) {
    //     event.preventDefault();
    //   }
    //   entry.mode = "X";
    //   return true;
    // }
  }
  handleDayInput(event: KeyboardEvent, entry: EntryInput) {
    if (!this.showDay) {
      return;
    }
    if (event.key === "*") {
      const currDay = entry.day;
      const currDayIndex = this.dayOptions.findIndex(
        (opt) => opt.value === currDay
      );
      const nextDayIndex =
        currDayIndex + 1 === this.dayOptions.length ? 0 : currDayIndex + 1;
      const nextDay = this.dayOptions[nextDayIndex];
      entry.day = nextDay.value as Day;
      event.preventDefault();
    }
  }
}
