
import { Vue, Component, Prop } from "vue-property-decorator";
import { TTEntryInput, TTDay, TTEntry } from "@/types";
import { parseTtEntryInputs } from "@/helpers/tt/parseTtEntryInputs";
import { validateTTNumbers } from "@/helpers/tt/validateTTNumbers";
import { validateTTNumber } from "@/helpers/tt/validateTTNumber";
import { calculateTtEntries } from "@/helpers/tt/calculateTtEntries";
import { getTtNumbers } from "@/helpers/tt/getTtNumbers";
import { calculateTtEntry } from "@/helpers/tt/calculateTtEntry";
import { compact } from "lodash";
import InputText from "primevue/inputtext/InputText";
import { useDialogStore } from "@/store/dialogs";

type NumberKey =
  | "number1"
  | "number2"
  | "number3"
  | "number4"
  | "number5"
  | "number6"
  | "number7"
  | "number8"
  | "number9"
  | "number10";

@Component
export default class TTMassEntryTable extends Vue {
  @Prop({ default: true }) showDay!: boolean;
  @Prop({ required: true }) selectedDay!: TTDay;
  @Prop({ default: 0 }) startingIndex!: number;
  @Prop({ required: true }) value!: TTEntryInput[];
  @Prop({ default: 10 }) NUMBERS_COUNT!: number;
  @Prop({ required: true }) dayOptions!: {
    label: string;
    value: number;
  }[];

  activeRow = 1;

  validateTTNumber = validateTTNumber;

  get rowCalculations() {
    const validatedEntries = parseTtEntryInputs(this.entries);

    return validatedEntries.map((entry) => calculateTtEntry(entry));
  }

  get numbers() {
    const result = [];
    for (let i = 1; i <= this.NUMBERS_COUNT; i++) {
      result.push(i);
    }
    return result;
  }

  get entries() {
    return this.value.slice(this.startingIndex, this.startingIndex + 20);
  }

  get validEntries() {
    return parseTtEntryInputs(this.entries);
  }

  get calculations() {
    return calculateTtEntries(compact(this.validEntries));
  }

  get modeOptions() {
    return [
      {
        label: "",
        value: undefined,
      },
      {
        label: "R",
        value: "R",
      },
      {
        label: "R2",
        value: "R2",
      },
      {
        label: "R3",
        value: "R3",
      },
      {
        label: "R4",
        value: "R4",
      },
      {
        label: "R5",
        value: "R5",
      },
      {
        label: "R7",
        value: "R7",
      },
      {
        label: "2*",
        value: "twoWild",
      },
      {
        label: "3*",
        value: "threeWild",
      },
      {
        label: "4*",
        value: "fourWild",
      },
      {
        label: "*2",
        value: "wild2",
      },
      {
        label: "*3",
        value: "wild3",
      },
      {
        label: "*4",
        value: "wild4",
      },
      {
        label: "TO",
        value: "TO",
      },
      {
        label: "TE",
        value: "TE",
      },
      {
        label: "TT",
        value: "TT",
      },
    ];
  }

  resetNumberField(entry: TTEntryInput, index: number) {
    switch (index) {
      case 1:
        entry.number1 = "";
        break;
      case 2:
        entry.number2 = "";
        break;
      case 3:
        entry.number3 = "";
        break;
      case 4:
        entry.number4 = "";
        break;
      case 5:
        entry.number5 = "";
        break;
      case 6:
        entry.number6 = "";
        break;
      case 7:
        entry.number7 = "";
        break;
      case 8:
        entry.number8 = "";
        break;
      case 9:
        entry.number9 = "";
        break;
      case 10:
        entry.number10 = "";
        break;
    }
  }

  checkNumberDuplicate(entry: TTEntryInput, number: string) {
    let sum = 0;
    if (entry.number1 === number) {
      sum++;
    }
    if (entry.number2 === number) {
      sum++;
    }
    if (entry.number3 === number) {
      sum++;
    }
    if (entry.number4 === number) {
      sum++;
    }
    if (entry.number5 === number) {
      sum++;
    }
    if (entry.number6 === number) {
      sum++;
    }
    if (entry.number7 === number) {
      sum++;
    }
    if (entry.number8 === number) {
      sum++;
    }
    if (entry.number9 === number) {
      sum++;
    }
    if (entry.number10 === number) {
      sum++;
    }
    return sum > 1;
  }

  handleNumberInput(
    val: string,
    field: "number" | "unit",
    index: number,
    numberIndex: number
  ) {
    const currEntry = this.entries[index];
    const number = currEntry[this.getNumberString(numberIndex)];
    const valid = validateTTNumber(number);

    if (val.length === 1 && !val.match(/[0-4]/)) {
      this.resetNumberField(currEntry, numberIndex);
      return;
    }
    if (val.length === 2 && (Number(val) < 1 || Number(val) > 49)) {
      this.resetNumberField(currEntry, numberIndex);
      return;
    }

    if (this.checkNumberDuplicate(currEntry, number)) {
      this.resetNumberField(currEntry, numberIndex);
      return;
    }

    if (val.match(/[0-9]/) && valid) {
      this.focus("number" + (numberIndex + 1), this.startingIndex + index);
    }
  }

  handleKeyPresses(event: KeyboardEvent, entry: TTEntryInput) {
    this.handleModeInput(event, entry);
    this.handleDayInput(event, entry);
  }

  handleEnter(
    event: KeyboardEvent,
    field: "number" | "unit" | "mode",
    index: number,
    numberIndex: number
  ) {
    const prevEntry = this.value[Math.max(index + this.startingIndex - 1, 0)];
    const currEntry = this.entries[index];

    if (event.key === "Enter") {
      switch (field) {
        case "number":
          this.focus("unit", index + this.startingIndex);
          break;
        case "unit":
          if (currEntry.unit === undefined || (currEntry.unit as any) === "") {
            currEntry.unit = prevEntry.unit || 1;
          }
          this.focus("number1", index + this.startingIndex + 1);
          break;
        case "mode":
          if (!validateTTNumbers(currEntry)) {
            return;
          }
          this.focus("number1", index + this.startingIndex + 1);
          break;
      }
      event.preventDefault();
      return;
    }
  }
  handleUnfocus(event: KeyboardEvent, field: "unit", index: number) {
    const currEntry = this.entries[index];
    switch (field) {
      case "unit":
        if (currEntry.unit && currEntry.unit > 500) {
          useDialogStore().addDialog({
            text: "Warning: Unit is > 500",
          });
          // currEntry.unit = 0;
        }
        break;
    }
  }

  getNumberString(numberIndex: number): NumberKey {
    return ("number" + numberIndex) as NumberKey;
  }

  handlePlusMinusKey(
    event: KeyboardEvent,
    field: "number" | "unit" | "mode",
    index: number,
    numberIndex: number
  ) {
    const currEntry = this.entries[index];
    if (event.key === "-") {
      switch (field) {
        case "number":
          currEntry[this.getNumberString(numberIndex)] = "";
          break;
        case "unit":
          currEntry.unit = undefined;
          break;
        case "mode":
          currEntry.mode = undefined;
          break;
      }
      event.preventDefault();
      return;
    } else if (event.key === "+") {
      if (index + this.startingIndex === 0) {
        return;
      }
      const prevEntry = this.value[index + this.startingIndex - 1];
      const prevValid = validateTTNumbers(prevEntry);
      if (!prevValid) {
        return;
      }
      currEntry.unit = prevEntry.unit;
      currEntry.mode = prevEntry.mode;
      const validation = validateTTNumbers(currEntry);
      event.preventDefault();
      if (!validation) {
        return;
      }
      this.focus("number1", index + 1 + this.startingIndex);
    }
  }

  handleArrowKey(
    event: KeyboardEvent,
    field: "number" | "unit" | "mode",
    index: number,
    numberIndex: number
  ) {
    const key = event.key;

    let indexDiff = 0;
    let targetField = field as string;

    let triggered = false;

    switch (key) {
      case "ArrowLeft":
        triggered = true;
        if (field === "number") {
          if (numberIndex === 1) {
            targetField = "unit";
            indexDiff--;
          } else {
            targetField = "number" + (numberIndex - 1);
          }
        } else if (field === "unit") {
          targetField = "number" + this.NUMBERS_COUNT;
        } else if (field === "mode") {
          targetField = "unit";
        }
        break;
      case "ArrowRight":
        triggered = true;
        if (field === "number") {
          if (numberIndex === this.NUMBERS_COUNT) {
            targetField = "unit";
          } else {
            targetField = "number" + (numberIndex + 1);
          }
        } else if (field === "unit") {
          targetField = "number1";
          indexDiff++;
        }
        break;
      case "ArrowUp":
        triggered = true;
        indexDiff--;
        targetField = field === "number" ? "number" + numberIndex : field;
        break;
      case "ArrowDown":
        triggered = true;
        indexDiff++;
        targetField = field === "number" ? "number" + numberIndex : field;
        break;
    }
    const targetIndex = index + indexDiff;
    // targetIndex = Math.min(targetIndex, 59);
    // targetIndex = Math.max(targetIndex, 0);
    if (triggered) {
      event.preventDefault();
      this.focus(targetField, targetIndex + this.startingIndex);
    }
  }

  focus(field: string, inputTargetindex: number) {
    // if index fall out of range, emit to focus on another table.
    if (
      inputTargetindex < this.startingIndex ||
      inputTargetindex >= this.startingIndex + 20
    ) {
      this.$emit("focus", field, inputTargetindex);
      return;
    }
    const targetIndex = inputTargetindex - this.startingIndex;
    const inputElements = this.$refs[field] as Vue[];
    if (!inputElements?.length) {
      return;
    }
    const inputElement = inputElements[targetIndex] as any;
    inputElement?.$el?.focus();
    if (field !== "mode") {
      inputElement?.$el?.select();
    }
    this.activeRow = inputTargetindex;
  }

  handleModeInput(event: KeyboardEvent, entry: TTEntryInput) {
    // don't allow roll if permutatations = 1.
    const pressedRoll = ["R", "/", "r"].includes(event.key);

    // const numbers = getTtNumbers(entry);

    // prevent roll on unrollable numbers
    if (pressedRoll) {
      // if (numbers.length < 3) {
      //   Vue.set(entry, "mode", undefined);
      //   event.preventDefault();
      //   return;
      // } else
      if (entry.mode === "R") {
        // is already roll, cancel
        Vue.set(entry, "mode", undefined);
        event.preventDefault();
      } else {
        entry.mode = "R";
        event.preventDefault();
      }
    }
  }

  handleDayInput(event: KeyboardEvent, entry: TTEntryInput) {
    if (!this.showDay) {
      return;
    }
    if (event.key === "*") {
      const currDay = entry.day;
      const currDayIndex = this.dayOptions.findIndex(
        (opt) => opt.value === currDay
      );
      const nextDayIndex =
        currDayIndex + 1 === this.dayOptions.length ? 0 : currDayIndex + 1;
      const nextDay = this.dayOptions[nextDayIndex];
      entry.day = nextDay.value as TTDay;
      event.preventDefault();
    }
  }
}
