
import { Vue, Component } from "vue-property-decorator";
import Tabs from "@/components/common/Tabs.vue";
import TtDownlineSummary from "@/components/modules/bet-details/TtDownlineSummary.vue";
import TTPageDetailsModal from "@/components/modules/bet-details/TTPageDetailsModal.vue";
import RenameTTPageModal from "@/components/modules/bet-details/RenameTTPageModal.vue";
import TTDeletePageModal from "@/components/modules/bet-details/TTDeletePageModal.vue";
import { queries, mutations } from "@/apollo";
import { User, StoredPageType, TtPage, AppMode } from "@/CodegenTypes";

import { sumBy, startCase, flatten, first } from "lodash";

import { formatDollar } from "@/helpers/formatDollar";
import { formatCount } from "@/helpers/formatCount";
import { errorMutationNotification } from "@/utils";
import { customMoment } from "@/main";
import { FEATURE_TOGGLES } from "@/config";
import { useTranslation } from "@/store/translation";

@Component({
  apollo: {
    user: {
      query: queries.userTtPagesSummary,
      skip() {
        return !this.selectedStartReleaseDate;
      },
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          releaseDateStart: customMoment(this.selectedStartReleaseDate)
            .startOf("day")
            .toDate(),
          releaseDateEnd: customMoment(this.selectedStartReleaseDate)
            .startOf("day")
            .toDate(),
          behalfOf: this.$route.query.userId,
        };
      },
    },
  },
  components: {
    TTPageDetailsModal,
    RenameTTPageModal,
    TTDeletePageModal,
    Tabs,
    TtDownlineSummary,
  },
})
export default class PageDetails extends Vue {
  user = null as null | User;

  showAccountSelectionModal = false;
  AppMode = AppMode;

  t = useTranslation().$t;
  FEATURE_TOGGLES = FEATURE_TOGGLES;

  startCase = startCase;

  formatDollar = formatDollar;
  formatCount = formatCount;

  selectedPage = null as null | TtPage;
  showPageDetailModal = false;
  showRenamePageModal = false;
  showDeletePageModal = false;

  tabs = [
    { id: "bet summary", name: "Bet Summary" },
    { id: "bet details", name: "Bet Details" },
  ];

  get selectedStartReleaseDate() {
    const query = this.$route.query.startReleaseDate;
    return Array.isArray(query) ? first(query) : query;
  }

  get downlineSummaryTotal() {
    const data = this.downlineSummaryData;
    return {
      amount: formatDollar(sumBy(data, (d) => d.amount)),
    };
  }

  get downlineSummaryData() {
    if (!this.user) {
      return [];
    }
    // const directDownlines = getDownlinesTree(this.user, this.user.downlines)
    //   .downlines;

    const userData = this.calcUserData(this.user);
    // const downlinesData = directDownlines
    //   .map((user) => {
    //     const allDownlines = flattenDownlines(user.downlines);
    //     const allPages = flatten([user, ...allDownlines].map((u) => u.ttPages));
    //     const amount = sumBy(allPages, (page) => page.calculation.unit);
    //     // const rebate = (amount * user.ddTicketRebate) / 100;
    //     // const payable = sumBy(
    //     //   allPages,
    //     //   page => (page.calculation.unit * (100 - user.ddTicketRebate)) / 100
    //     // );
    //     // const payable = (amount * (100 - user.ddTicketRebate)) / 100;
    //     return {
    //       account: user.userId,
    //       username: user.username,
    //       rebatePercent: user.ddTicketRebate,
    //       // rebate,
    //       unit: sumBy(allPages, (p) => p.calculation.unit),
    //       amount,
    //       // payable,
    //       createdAt: user.createdAt,
    //     };
    //   })
    //   .filter((u) => u.amount > 0)
    //   .sort(
    //     (u1, u2) =>
    //       customMoment(u1.createdAt).unix() - customMoment(u2.createdAt).unix()
    //   )
    // .sort((u1, u2) => u1.account.localeCompare(u2.account));
    // const individualData = [userData, ...downlinesData];
    const individualData = [userData];
    return individualData;
  }

  calcUserData(user: User) {
    const amount = sumBy(user.ttPages, (page) => page.calculation.unit);
    // const rebate = (amount * user.ddTicketRebate) / 100;
    // const payable = (amount * (100 - user.ddTicketRebate)) / 100;
    return {
      account: user.userId,
      username: user.username,
      rebatePercent: user.ddTicketRebate,
      // rebate,
      unit: sumBy(user.ttPages, (page) => page.calculation.unit),
      amount,
      // payable
    };
  }

  get pageDetailTotal() {
    const data = this.pageDetailsData;
    return {
      amount: formatDollar(sumBy(data, (d) => d.amount)),
      // rebate: formatDollar(sumBy(data, d => d.rebate)),
      // payable: formatDollar(sumBy(data, d => d.payable))
    };
  }

  get pageDetailsData() {
    const currUser = this.user;
    if (!currUser) {
      return [];
    }
    // const currUserTree = getDownlinesTree(currUser, currUser.downlines);
    // const directDownlines = currUserTree.downlines;

    // insert self in
    const mockCurrUser = {
      ...currUser,
      downlines: [],
    };

    const downlinesData = flatten(
      // [mockCurrUser, ...directDownlines].map((directDownline) => {
      [mockCurrUser].map((directDownline) => {
        const allPages = directDownline.ttPages;

        return allPages.map((page) => {
          const amount = page.calculation.unit;
          // const rebate = (amount * directDownline.ddTicketRebate) / 100;
          // const payable =
          //   (amount * (100 - directDownline.ddTicketRebate)) / 100;
          return {
            ...page,
            upline: directDownline.userId,
            uplineName: directDownline.username,
            account: page.userId,
            username: page.username,
            // rebate,
            amount,
            // payable,
            createdAt: page.createdAt,
          };
        });
      })
    )
      .filter((u) => u.amount > 0)
      .sort(
        (u1, u2) =>
          customMoment(u1.createdAt).unix() - customMoment(u2.createdAt).unix()
      )
      .sort((u1, u2) => u1.account.localeCompare(u2.account))
      .sort((u1, u2) => u1.upline.localeCompare(u2.upline));

    const rowData = downlinesData.map((row) => {
      return {
        ...row,
        uplineCount: downlinesData.filter((d) => d.upline === row.upline)
          .length,
        accountCount: downlinesData.filter((d) => d.account === row.account)
          .length,
      };
    });

    return rowData;
  }

  formatDate(dateStr: string) {
    return customMoment(dateStr).format("ddd, DD/MM/YYYY hh:mm:ss A");
  }

  async savePage(id: TtPage["id"]) {
    const result = await this.$apollo.query<{ ttPage: TtPage }>({
      query: queries.ttPage,
      variables: {
        id,
      },
    });
    const page = result.data.ttPage;
    if (!page) {
      return;
    }

    this.$confirm.require({
      header: "Save bet?",
      message: "Bet page will be saved under Saved Bets",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          
          const result = await this.$apollo.mutate({
            mutation: mutations.saveTtEntry,
            variables: {
              page: {
                id: undefined,
                page: page.name,
                userId: page.userId,
                entries: page.entries.map((e) => {
                  const numbers = e.numbersText.split('-');
                 return {
                  number1: numbers[0],
                  number2: numbers[1],
                  number3: numbers[2],
                  number4: numbers[3],
                  number5: numbers[4],
                  number6: numbers[5],
                  number7: numbers[6],
                  number8: numbers[7],
                  number9: numbers[8],
                  number10: numbers[9],
                  day: 4,
                  unit: e.unit,
                  mode: e.mode,
                }
                }),
                storedPageType: StoredPageType.Saved,
              },
            },
          });
          const pageId = result.data.saveTtEntry;

          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Page saved to Saved Bets",
            life: 3000,
          });

          this.$router.push({
            name: "tt saved bet",
            query: { pageId, userId: page.userId },
          });
        } catch (err) {
          errorMutationNotification(err);
        }
      },
      reject: () => {
        // Optional: Handle rejection
      },
    });

    return id;
  }

  deletePage(id: TtPage["id"]) {
    const page = this.pageDetailsData.find((p) => p.id === id);
    if (!page) {
      return;
    }
    this.selectedPage = page;
    this.showDeletePageModal = true;
  }

  renamePage(id: TtPage["id"]) {
    const page = this.pageDetailsData.find((p) => p.id === id);
    if (!page) {
      return;
    }
    this.selectedPage = page;
    this.showRenamePageModal = true;
  }

  reloadPageName() {
    this.$apollo.queries.user.refetch();
  }
  reloadAll() {
    Object.values(this.$apollo.queries).forEach((q) => q.refetch());
  }

  viewPageDetails(id: TtPage["id"]) {
    const page = this.pageDetailsData.find((p) => p.id === id);
    if (!page) {
      return;
    }
    this.selectedPage = page;
    this.showPageDetailModal = true;
  }
}
