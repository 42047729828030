import gql from "graphql-tag";

import { fragments } from "./fragments";
export const hybridQueries = {
  sideNav: gql`
    ${fragments.FullUser}
    ${fragments.CreditSummary}
    ${fragments.Payment}
    query SideNav($appMode: AppMode!) {
      me {
        isSuperUser
        ...FullUser
        paymentPayable {
          ...Payment
        }
        ...CreditSummary
      }
      releases(appMode: $appMode) {
        id
        releaseDate
        windowCloseAt
        windowIsOpen
      }
    }
  `,
};
