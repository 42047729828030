
import { Vue, Component, Prop } from "vue-property-decorator";
import { User, AppMode, StatusEnum } from "@/CodegenTypes";

import { sumBy, first } from "lodash";
import { formatDollar } from "@/helpers/formatDollar";
import { formatCount } from "@/helpers/formatCount";
import { queries } from "@/apollo";
import { useTranslation } from "@/store/translation";

@Component({
  apollo: {
    user: {
      query: queries.userAndDownlineTtBetSummary,
      skip() {
        return !this.userId;
      },
      variables() {
        return {
          releaseDate: this.selectedStartReleaseDate,
          behalfOf: this.userId,
        };
      },
    },
  },
})
export default class TtDownlineSummary extends Vue {
  @Prop({ required: true }) userId!: User["userId"];
  formatDollar = formatDollar;
  formatCount = formatCount;
  AppMode = AppMode;

  t = useTranslation().$t;

  user = null as null | User;

  get selectedStartReleaseDate() {
    const query = this.$route.query.startReleaseDate;
    return Array.isArray(query) ? first(query) : query;
  }

  get downlineSummaryTotal() {
    const data = this.downlineSummaryData;
    return {
      amount: formatDollar(sumBy(data, (d) => d.amount)),
    };
  }

  get downlineSummaryData() {
    if (!this.user) {
      return [];
    }

    const directDownlines = this.user.downlines;
    const userSummary = {
      userId: this.user.userId,
      username: this.user.username,
      amount: this.user.ttBetSummary.unit,
    };
    const downlinesSummary = directDownlines
      .map((user) => {
        const amount = user.ttBetSummary.unit;
        return {
          userId: user.userId,
          username: user.username,
          status: user.status,
          amount,
        };
      })
      .sort((u1, u2) => u1.userId.localeCompare(u2.userId));

    const filteredDownlinesSummary = downlinesSummary.filter(dl => dl.status === StatusEnum.Live || dl.amount > 0);


    return [userSummary, ...filteredDownlinesSummary];
  }

  calcUserData(user: User) {
    const amount = sumBy(user.pages, (page) => page.calculation.rawCost);
    const rebate = (amount * user.ddTicketRebate) / 100;
    const payable = (amount * (100 - user.ddTicketRebate)) / 100;
    return {
      account: user.userId,
      username: user.username,
      rebatePercent: user.ddTicketRebate,
      rebate,
      big: sumBy(user.pages, (page) => page.calculation.bigCount),
      small: sumBy(user.pages, (page) => page.calculation.smallCount),
      amount,
      payable,
    };
  }
}
