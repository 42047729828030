import gql from "graphql-tag";

import { fragments } from "./fragments";
export default {
  tnc: gql`
    query tnc {
      tnc
    }
  `,
  systemTime: gql`
    query systemTime {
      systemTime
    }
  `,
    mainLayout: gql`
    query MainLayout($appMode: AppMode!) {
      systemTime
      scrollingText(appMode: $appMode)
    }
  `,
  telegramBotName: gql`
    query telegramBotName($appMode: AppMode!) {
      telegramBotName(appMode: $appMode)
    }
  `,
  scrollingText: gql`
    query scrollingText($appMode: AppMode!) {
      scrollingText(appMode: $appMode)
    }
  `,
  announcements: gql`
    query announcements($appMode: AppMode!) {
      announcements(appMode: $appMode) {
        id
        createdAt
        mode
        text
      }
    }
  `,
  windowSettings: gql`
    query windowSettings($appMode: AppMode!) {
      windowSettings(appMode: $appMode) {
        drawWindow {
          close
          reopen
        }
        fixedBet {
          close
          calculate
        }
        profileChange {
          close
          reopen
        }
      }
    }
  `,
  me: gql`
    ${fragments.FullUser}
    query me {
      me {
        isSuperUser
        ...FullUser
      }
    }
  `,
  meWithCreditSummary: gql`
    ${fragments.FullUser}
    ${fragments.CreditSummary}
    ${fragments.Payment}
    query me {
      me {
        isSuperUser
        ...FullUser
        paymentPayable {
          ...Payment
        }
        ...CreditSummary
      }
    }
  `,
  meWithAllDownlines: gql`
    query meWithAllDownlines {
      me {
        userId
        username
        uplineId
        downlines(includeAll: true) {
          userId
          username
          uplineId
        }
      }
    }
  `,
  meWithDownlinePayments: gql`
    ${fragments.FullUser}
    ${fragments.CreditSummary}
    ${fragments.Payment}
    query meWithDownlinePayments {
      me {
        isSuperUser
        ...FullUser
        ...CreditSummary
        paymentHistory {
          ...Payment
        }
        downlines {
          ...FullUser
          ...CreditSummary
          paymentHistory {
            ...Payment
          }
        }
      }
    }
  `,
  userWithDownlinePaymentPayableSum: gql`
    ${fragments.CreditSummary}
    query userWithDownlinePaymentPayableSum($userId: String!) {
      user(userId: $userId) {
        userId
        downlines {
          userId
          username
          autoResetBalance
          ...CreditSummary
          paymentPayableSum
        }
      }
    }
  `,
  userWithDownlinePayments: gql`
    ${fragments.CreditSummary}
    ${fragments.Payment}
    query userWithDownlinePayments($userId: String!) {
      user(userId: $userId) {
        userId
        downlines {
          userId
          username
          autoResetBalance
          ...CreditSummary
          paymentPayable {
            ...Payment
          }
        }
      }
    }
  `,
  userWithPaymentHistory: gql`
    ${fragments.Payment}
    query userWithPaymentHistory($userId: String!, $withUser: String) {
      user(userId: $userId) {
        userId
        lineageIds
        paymentHistory(withUser: $withUser) {
          ...Payment
        }
      }
    }
  `,
  user: gql`
    ${fragments.FullUser}
    query user($userId: String!) {
      user(userId: $userId) {
        ...FullUser
      }
    }
  `,
  userWithCreditSummary: gql`
    ${fragments.FullUser}
    ${fragments.CreditSummary}
    query user($userId: String!) {
      user(userId: $userId) {
        ...FullUser
        ...CreditSummary
      }
    }
  `,
  userWithDownlines: gql`
    ${fragments.FullUser}

    query user($userId: String!) {
      user(userId: $userId) {
        ...FullUser
        downlines {
          ...FullUser
          downlinesCount
        }
      }
    }
  `,
  userWithDownlinesAndCredits: gql`
    ${fragments.FullUser}
    ${fragments.CreditSummary}

    query user($userId: String!) {
      user(userId: $userId) {
        ...FullUser
        downlines {
          ...FullUser
          ...CreditSummary
          downlinesCount
        }
      }
    }
  `,
  releases: gql`
    ${fragments.Release}
    query releases($appMode: AppMode, $weeksBackCount: NonNegativeInt) {
      releases(appMode: $appMode, weeksBackCount: $weeksBackCount) {
        ...Release
      }
    }
  `,
  releasesWithStrikeReports: gql`
    ${fragments.Release}
    query releasesWithStrikeSummaryReport(
      $appMode: AppMode
      $dateRange: ReleaseDateRangeInput
    ) {
      releases(appMode: $appMode, dateRange: $dateRange) {
        ...Release
        strikeSummaryReports {
          number
          strikeType

          companyBig
          companySmall
          companyAmount

          outsetAmount
        }
        strikeByNumberReport {
          number
          big
          small
          strikeAmount
          userId
          time
          page
          strikeType
          entrySource
        }
      }
    }
  `,
  releasesWithTtStrikeReports: gql`
    ${fragments.Release}
    query releasesWithTtStrikeReports(
      $appMode: AppMode
      $dateRange: ReleaseDateRangeInput
    ) {
      releases(appMode: $appMode, dateRange: $dateRange) {
        ...Release
        ttStrikeSummaryReports {
          numbers

          companyUnit
          companyStrikeAmount

          outsetAmount
        }
        ttStrikeByNumberReport {
          numbers
          unit
          strikeAmount
          userId
          time
          page
          entrySource
        }
      }
    }
  `,
  releasesWithOutset: gql`
    ${fragments.Release}
    query releases($appMode: AppMode, $weeksBackCount: NonNegativeInt) {
      releases(appMode: $appMode, weeksBackCount: $weeksBackCount) {
        ...Release
        outsets {
          id
          fileName
          page
          externalCompanyId
          entries
          readyAt
          confirmedAt
          createdAt
        }
        ttOutsets {
          id
          fileName
          page
          externalCompanyId
          entries
          readyAt
          confirmedAt
          createdAt
        }
      }
    }
  `,
  releasesWithResults: gql`
    ${fragments.Release}
    query releasesWithResults(
      $appMode: AppMode
      $weeksBackCount: NonNegativeInt
    ) {
      releases(appMode: $appMode, weeksBackCount: $weeksBackCount) {
        ...Release
        autoCalculateBinBreak
        ddStrikeConfig {
          strikeType
          bigPayout
          smallPayout
        }
        ddResults {
          consolation1
          consolation2
          consolation3
          consolation4
          consolation5
          consolation6
          consolation7
          consolation8
          consolation9
          consolation10
          starter1
          starter2
          starter3
          starter4
          starter5
          starter6
          starter7
          starter8
          starter9
          starter10
          firstPrize
          secondPrize
          thirdPrize
        }
        ttResults {
          winningNumber1
          winningNumber2
          winningNumber3
          winningNumber4
          winningNumber5
          winningNumber6
          additionalNumber
        }
        generateReportState
      }
    }
  `,
  pageReleases: gql`
    query pageReleases($id: UUID!) {
      page(id: $id) {
        releases {
          id
          releaseDate
          locked
          isOver
        }
      }
    }
  `,
  page: gql`
    ${fragments.DDPageSimple}
    query page($id: UUID!, $releaseDates: [Date!]) {
      page(id: $id) {
        ...DDPageSimple
        entries(releaseDates: $releaseDates)
        summary(releaseDates: $releaseDates) {
          bigCost
          smallCost
          bigCount
          smallCount
          rawCost
        }
      }
    }
  `,
  ttPageReleases: gql`
    query ttPageReleases($id: UUID!) {
      ttPage(id: $id) {
        releases {
          id
          releaseDate
          locked
          isOver
        }
      }
    }
  `,
  ttPage: gql`
    ${fragments.TTPageSimple}
    query ttPage($id: UUID!, $releaseDates: [Date!]) {
      ttPage(id: $id) {
        ...TTPageSimple
        entries(releaseDates: $releaseDates)
        summary(releaseDates: $releaseDates) {
          unit
        }
      }
    }
  `,
  deletedPage: gql`
    ${fragments.DeletedDDPageWithEntries}
    query deletedPage($id: UUID!) {
      deletedPage(id: $id) {
        ...DeletedDDPageWithEntries
      }
    }
  `,
  deletedTtPage: gql`
    ${fragments.DeletedTTPageWithEntries}
    query deletedTtPage($id: UUID!) {
      deletedTtPage(id: $id) {
        ...DeletedTTPageWithEntries
      }
    }
  `,
  userPages: gql`
    ${fragments.FullUser}
    ${fragments.CreditSummary}
    ${fragments.FullDDPage}
    query userPages(
      $behalfOf: String!
      $releaseDateStart: Date!
      $releaseDateEnd: Date!
    ) {
      user(userId: $behalfOf) {
        ...FullUser
        ...CreditSummary
        pages(
          releaseDateStart: $releaseDateStart
          releaseDateEnd: $releaseDateEnd
          includeDownlines: false
          reportMode: false
        ) {
          ...FullDDPage
        }
      }
    }
  `,
  userPagesSummary: gql`
    ${fragments.MinimalUser}
    ${fragments.SummaryDDPage}
    query userPages(
      $behalfOf: String!
      $releaseDateStart: Date!
      $releaseDateEnd: Date!
    ) {
      user(userId: $behalfOf) {
        ...MinimalUser
        pages(
          releaseDateStart: $releaseDateStart
          releaseDateEnd: $releaseDateEnd
          includeDownlines: false
          reportMode: false
        ) {
          ...SummaryDDPage
        }
      }
    }
  `,
  userAndDownlineBetSummary: gql`
    query userAndDownlineBetSummary($behalfOf: String!, $releaseDate: Date!) {
      user(userId: $behalfOf) {
        userId
        username
        ddTicketRebate
        betSummary(releaseDate: $releaseDate, includeDownlines: false) {
          big
          small
        }
        downlines(includeAll: false) {
          userId
          username
          status
          uplineId
          ddTicketRebate
          betSummary(releaseDate: $releaseDate, includeDownlines: true) {
            big
            small
          }
        }
      }
    }
  `,
  userAndDownlineTtBetSummary: gql`
    query userAndDownlineTtBetSummary($behalfOf: String!, $releaseDate: Date!) {
      user(userId: $behalfOf) {
        userId
        username
        ttBetSummary(releaseDate: $releaseDate, includeDownlines: false) {
          unit
        }
        downlines(includeAll: false) {
          userId
          username
          status
          uplineId
          ttBetSummary(releaseDate: $releaseDate, includeDownlines: true) {
            unit
          }
        }
      }
    }
  `,
  userTtPages: gql`
    ${fragments.FullUser}
    ${fragments.CreditSummary}
    ${fragments.FullTTPage}
    query userTtPages(
      $behalfOf: String!
      $releaseDateStart: Date!
      $releaseDateEnd: Date!
    ) {
      user(userId: $behalfOf) {
        ...FullUser
        ...CreditSummary
        ttPages(
          releaseDateStart: $releaseDateStart
          releaseDateEnd: $releaseDateEnd
          includeDownlines: false
          reportMode: false
        ) {
          ...FullTTPage
        }
      }
    }
  `,
  userTtPagesSummary: gql`
    ${fragments.MinimalUser}
    ${fragments.SummaryTTPage}
    query userTtPages(
      $behalfOf: String!
      $releaseDateStart: Date!
      $releaseDateEnd: Date!
    ) {
      user(userId: $behalfOf) {
        ...MinimalUser
        ttPages(
          releaseDateStart: $releaseDateStart
          releaseDateEnd: $releaseDateEnd
          includeDownlines: false
          reportMode: false
        ) {
          ...SummaryTTPage
        }
      }
    }
  `,
  userReportPages: gql`
    ${fragments.MinimalUser}
    ${fragments.DDPage}
    query userReportPages(
      $behalfOf: String!
      $releaseDateStart: Date!
      $releaseDateEnd: Date!
      $includeDownlines: Boolean!
    ) {
      user(userId: $behalfOf) {
        ...MinimalUser
        pages(
          releaseDateStart: $releaseDateStart
          releaseDateEnd: $releaseDateEnd
          includeDownlines: $includeDownlines
          reportMode: true
        ) {
          ...DDPage
        }
      }
    }
  `,
  userTtReportPages: gql`
    ${fragments.MinimalUser}
    ${fragments.FullTTPage}
    query userTtReportPages(
      $behalfOf: String!
      $releaseDateStart: Date!
      $releaseDateEnd: Date!
      $includeDownlines: Boolean!
    ) {
      user(userId: $behalfOf) {
        ...MinimalUser
        ttPages(
          releaseDateStart: $releaseDateStart
          releaseDateEnd: $releaseDateEnd
          includeDownlines: $includeDownlines
          reportMode: true
        ) {
          ...FullTTPage
        }
      }
    }
  `,
  userStakes: gql`
    query userStakes($behalfOf: String!, $releaseDate: Date!) {
      userStakes(behalfOf: $behalfOf, releaseDate: $releaseDate) {
        masterStake
        downlineEatStake
      }
    }
  `,
  ttUserStakes: gql`
    query ttUserStakes($behalfOf: String!, $releaseDate: Date!) {
      ttUserStakes(behalfOf: $behalfOf, releaseDate: $releaseDate) {
        masterStake
        downlineEatStake
      }
    }
  `,
  numberStakes: gql`
    query numberStakes(
      $appMode: AppMode!
      $behalfOf: String!
      $releaseDate: Date!
      $number: String!
    ) {
      numberStakes(
        appMode: $appMode
        behalfOf: $behalfOf
        releaseDate: $releaseDate
        number: $number
      ) {
        userId
        number
        in {
          big
          small
          amount
        }
        intake {
          big
          small
          amount
        }
        toCompany {
          big
          small
          amount
        }
      }
    }
  `,
  ttNumberStakes: gql`
    query ttNumberStakes(
      $behalfOf: String!
      $releaseDate: Date!
      $number: String!
    ) {
      ttNumberStakes(
        behalfOf: $behalfOf
        releaseDate: $releaseDate
        number: $number
      ) {
        userId
        number
        in
        intake
        toCompany
      }
    }
  `,
  userIntakes: gql`
    query userIntakes($userId: String!, $releaseDate: Date!) {
      user(userId: $userId) {
        userId
        intakes(releaseDate: $releaseDate, includeDownlines: true) {
          intakerUserId
          intakerUsername
          rows
        }
      }
    }
  `,
  userTtIntakes: gql`
    query userTtIntakes($userId: String!, $releaseDate: Date!) {
      user(userId: $userId) {
        userId
        ttIntakes(releaseDate: $releaseDate, includeDownlines: true) {
          intakerUserId
          intakerUsername
          rows
        }
      }
    }
  `,
  userDeletedPages: gql`
    ${fragments.MinimalUser}
    ${fragments.FullDeletedDDPage}
    query userDeletedPages($behalfOf: String!, $releaseDate: Date!) {
      user(userId: $behalfOf) {
        ...MinimalUser
        deletedPages(releaseDate: $releaseDate) {
          ...FullDeletedDDPage
        }
      }
    }
  `,
  userDeletedTtPages: gql`
    ${fragments.MinimalUser}
    ${fragments.FullDeletedTTPage}
    query userDeletedTtPages($behalfOf: String!, $releaseDate: Date!) {
      user(userId: $behalfOf) {
        ...MinimalUser
        deletedTtPages(releaseDate: $releaseDate) {
          ...FullDeletedTTPage
        }
      }
    }
  `,
  userStoredPages: gql`
    ${fragments.FullStoredDDPage}
    query userStoredPages(
      $userId: String!
      $storedPageType: StoredPageType!
      $includeDownlines: Boolean
    ) {
      user(userId: $userId) {
        storedPages(
          storedPageType: $storedPageType
          includeDownlines: $includeDownlines
        ) {
          username
          userId
          ...FullStoredDDPage
        }
      }
    }
  `,
  userStoredTtPages: gql`
    ${fragments.FullStoredTTPage}
    query userStoredTtPages(
      $userId: String!
      $storedPageType: StoredPageType!
      $includeDownlines: Boolean
    ) {
      user(userId: $userId) {
        storedTtPages(
          storedPageType: $storedPageType
          includeDownlines: $includeDownlines
        ) {
          username
          userId
          ...FullStoredTTPage
        }
      }
    }
  `,
  strikeNumberReports: gql`
    query strikeNumberReports(
      $releaseDateStart: Date!
      $releaseDateEnd: Date!
      $userId: String!
      $statementType: StatementType
    ) {
      strikeNumberReports(
        releaseDateStart: $releaseDateStart
        releaseDateEnd: $releaseDateEnd
        userId: $userId
        statementType: $statementType
      ) {
        releaseDate
        rows {
          number
          big
          small
          strikeAmount
          userId
          time
          page
          strikeType
          entrySource
        }
      }
    }
  `,
  ttStrikeNumberReports: gql`
    query ttStrikeNumberReports(
      $releaseDateStart: Date!
      $releaseDateEnd: Date!
      $userId: String!
      $statementType: StatementType
    ) {
      ttStrikeNumberReports(
        releaseDateStart: $releaseDateStart
        releaseDateEnd: $releaseDateEnd
        userId: $userId
        statementType: $statementType
      ) {
        releaseDate
        rows {
          numbers
          strikeAmount
          unit
          userId
          time
          page
          entrySource
        }
      }
    }
  `,
  ttStrikeNumberOutsetReports: gql`
    query ttStrikeNumberOutsetReports(
      $releaseDateStart: Date!
      $releaseDateEnd: Date!
      $userId: String
    ) {
      ttStrikeNumberOutsetReports(
        releaseDateStart: $releaseDateStart
        releaseDateEnd: $releaseDateEnd
        userId: $userId
      ) {
        releaseDate
        rows {
          numbersText
          unit
          strikeAmount
          userId
          time
          page
        }
      }
    }
  `,
  strikeNumberOutsetReports: gql`
    query strikeNumberOutsetReports(
      $releaseDateStart: Date!
      $releaseDateEnd: Date!
      $userId: String
    ) {
      strikeNumberOutsetReports(
        releaseDateStart: $releaseDateStart
        releaseDateEnd: $releaseDateEnd
        userId: $userId
      ) {
        releaseDate
        rows {
          number
          big
          small
          strikeAmount
          userId
          time
          page
          strikeType
        }
      }
    }
  `,
  loginHistory: gql`
    query loginHistory($date: Date) {
      loginHistory(date: $date) {
        # index
        createdAt
        userId
        actionId
        properties
      }
    }
  `,
  auditLogs: gql`
    query auditLogs($date: Date) {
      auditLogs(date: $date) {
        # index
        type
        createdAt
        userId
        actionId
        properties
      }
    }
  `,
  stakeFile: gql`
    query stakeFile($releaseDate: Date!) {
      stakeFile(releaseDate: $releaseDate) {
        masterStake
        downlineEatStake
        outsetStake
        # forOutsetStake
        companyFinalStake
      }
    }
  `,
  ttStakeFile: gql`
    query ttStakeFile($releaseDate: Date!) {
      ttStakeFile(releaseDate: $releaseDate) {
        masterStake
        downlineEatStake
        outsetStake
        companyFinalStake
      }
    }
  `,
  stakeFileLive: gql`
    query stakeFile($releaseDate: Date!) {
      stakeFile(releaseDate: $releaseDate, live: true) {
        masterStake
        downlineEatStake
        outsetStake
        forOutsetStake
        companyFinalStake
      }
    }
  `,
  ttStakeFileLive: gql`
    query ttStakeFile($releaseDate: Date!) {
      ttStakeFile(releaseDate: $releaseDate, live: true) {
        masterStake
        downlineEatStake
        outsetStake
        forOutsetStake
        companyFinalStake
      }
    }
  `,
  externalCompanies: gql`
    query externalCompanies {
      externalCompanies {
        id
        name
        userId
        loginId
        loginPassword
        timeClose
        bigLimit
        smallLimit
        unitLimit
        creditLimit
        commission
        priority
      }
    }
  `,
  fullReport: gql`
    query fullReport(
      $releaseDateStart: Date!
      $releaseDateEnd: Date!
      $userId: String!
    ) {
      fullReport(
        releaseDateStart: $releaseDateStart
        releaseDateEnd: $releaseDateEnd
        userId: $userId
      ) {
        downlines {
          userId
          username
          big
          small
          amount
          rebate
          amountLessRebate
          userTicketRebate
          userCompanyPl
          strikeAmount
          strikeCommission
          net
          companyStake
          position
          variableExpenses
          fixedExpenses
          # uplinePosition
          # outsetClaimPosition
          netBal
          positionStatements {
            positionType
            releaseDate
            uplineId
            uplineIntakeAmount
            uplineIntakeNet

            contributeAmount
            uplineStake
            uplinePlFight

            uplinePosition
            
          }
        }
        personalTickets {
          userId
          big
          small
          amount
          rebate
          amountLessRebate
          strikeAmount
          strikeCommission
          companyStake
          fixedExpenses
          userCompanyPl
          position
          net
          netBal
        }
        intakeTickets {
          userId
          big
          small
          amount
          rebate
          amountLessRebate
          strikeAmount
          strikeCommission
          net
        }
        consolidated {
          userId
          big
          small
          amount
          rebate
          amountLessRebate
          userTicketRebate
          userCompanyPl
          strikeAmount
          strikeCommission
          net
          companyStake
          variableExpenses
          fixedExpenses
          position
          positionStatements {
            positionType
            releaseDate
            uplineId
            uplineIntakeAmount
            uplineIntakeNet

            contributeAmount
            uplineStake
            uplinePlFight

            uplinePosition
          }
          # uplinePosition
          # outsetClaimPosition
          netBal
        }
      }
    }
  `,
  ttFullReport: gql`
    query ttFullReport(
      $releaseDateStart: Date!
      $releaseDateEnd: Date!
      $userId: String!
    ) {
      ttFullReport(
        releaseDateStart: $releaseDateStart
        releaseDateEnd: $releaseDateEnd
        userId: $userId
      ) {
        downlines {
          userId
          username
          amount
          rebate
          amountLessRebate
          userTicketRebate
          userCompanyPl
          strikeAmount
          net
          companyStake
          position
          variableExpenses
          fixedExpenses
          # uplinePosition
          # outsetClaimPosition
          netBal
          positionStatements {
            positionType
            releaseDate
            uplineId
            uplineIntakeAmount
            uplineIntakeNet

            numCount

            contributeAmount
            uplineStake
            uplinePlFight

            uplinePosition
          }
        }
        personalTickets {
          userId
          amount
          rebate
          amountLessRebate
          strikeAmount
          companyStake
          fixedExpenses
          userCompanyPl
          position
          net
          netBal
        }
        intakeTickets {
          userId
          amount
          rebate
          amountLessRebate
          strikeAmount
          net
        }
        consolidated {
          userId
          amount
          rebate
          amountLessRebate
          userTicketRebate
          userCompanyPl
          strikeAmount
          net
          companyStake
          variableExpenses
          fixedExpenses
          position
          positionStatements {
            positionType
            releaseDate
            uplineId
            uplineIntakeAmount
            uplineIntakeNet

            numCount

            contributeAmount
            uplineStake
            uplinePlFight

            uplinePosition
          }
          # uplinePosition
          # outsetClaimPosition
          netBal
        }
      }
    }
  `,
  companyReport: gql`
    query companyReport($releaseDateStart: Date!, $releaseDateEnd: Date!) {
      companyReport(
        releaseDateStart: $releaseDateStart
        releaseDateEnd: $releaseDateEnd
      ) {
        companyTickets {
          rows {
            name
            big
            small
            amount
            rebate
            amountLessRebate
            strikeAmount
            position
            net
          }
          summary {
            name
            big
            small
            amount
            rebate
            amountLessRebate
            strikeAmount
            position
            net
          }
        }
        outsetTickets {
          rows {
            name
            big
            small
            amount
            rebate
            amountLessRebate
            strikeAmount
            externalCompanyTicketRebate
            position
            externalShareClaim
            net
          }
          summary {
            name
            big
            small
            amount
            rebate
            amountLessRebate
            strikeAmount
            position
            externalShareClaim
            net
          }
        }
        companyTicketsFinal {
          rows {
            name
            big
            small
            amount
            rebate
            amountLessRebate
            strikeAmount
            position
            net
          }
          summary {
            name
            big
            small
            amount
            rebate
            amountLessRebate
            strikeAmount
            net
          }
        }

        companyTicketsFinal {
          rows {
            name
            big
            small
            amount
            rebate
            amountLessRebate
            strikeAmount
            position
            net
          }
          summary {
            name
            big
            small
            amount
            rebate
            amountLessRebate
            strikeAmount
            position
            net
          }
        }
      }
    }
  `,
  ttCompanyReport: gql`
    query ttCompanyReport(
      $releaseDateStart: Date!
      $releaseDateEnd: Date!
      $numCount: Int
    ) {
      ttCompanyReport(
        releaseDateStart: $releaseDateStart
        releaseDateEnd: $releaseDateEnd
        numCount: $numCount
      ) {
        companyTickets {
          rows {
            name
            amount
            rebate
            amountLessRebate
            strikeAmount
            position
            net
          }
          summary {
            name
            amount
            rebate
            amountLessRebate
            strikeAmount
            position
            net
          }
        }
        outsetTickets {
          rows {
            name
            amount
            rebate
            amountLessRebate
            strikeAmount
            position
            externalShareClaim
            net
          }
          summary {
            name
            amount
            rebate
            amountLessRebate
            strikeAmount
            position
            externalShareClaim
            net
          }
        }
        companyTicketsFinal {
          rows {
            name
            amount
            rebate
            amountLessRebate
            strikeAmount
            position
            net
          }
          summary {
            name
            amount
            rebate
            amountLessRebate
            strikeAmount
            net
          }
        }
        companyTicketsFinal {
          rows {
            name
            amount
            rebate
            amountLessRebate
            strikeAmount
            position
            net
          }
          summary {
            name
            amount
            rebate
            amountLessRebate
            strikeAmount
            position
            net
          }
        }
      }
    }
  `,
  summaryReport: gql`
    query summaryReport($userId: String!) {
      user(userId: $userId) {
        ddCompanyPl
        ttD1CompanyPl
        ttD2CompanyPl
        ttD3CompanyPl
        ttD4CompanyPl

        ddPlaceoutPl
        ttD1PlaceoutPl
        ttD2PlaceoutPl
        ttD3PlaceoutPl
        ttD4PlaceoutPl
        

        summaryReport {
          date
          description
          amount
          rebate
          amountLessRebate
          strikeAmount
          strikeCommission
          net
          position
          shareClaim
          netBal
        }
      }
    }
  `,
  externalCompaniesWithOutsetSummary: gql`
    query externalCompaniesWithOutsetSummary($releaseDate: Date!) {
      externalCompanies {
        id
        name
        userId
        loginId
        loginPassword
        outsetClaim(releaseDate: $releaseDate) {
          position
          shareClaim
          outsetBinBreaks {
            strikeType
            bigPayout
            smallPayout
          }
        }
        outsetSummary(releaseDate: $releaseDate) {
          big
          small
          amount
        }
        ttOutsetClaim(releaseDate: $releaseDate) {
          id
          position
          shareClaim
          numCount
          outsetBinBreaks {
            payout
            numbersText
          }
        }
        ttOutsetSummary(releaseDate: $releaseDate) {
          unit
        }
      }
    }
  `,
  isProfileChangeWindowOpen: gql`
    query isProfileChangeWindowOpen($userId: String!, $appMode: AppMode!) {
      isProfileChangeWindowOpen(userId: $userId, appMode: $appMode)
    }
  `,
};
